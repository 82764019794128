import { ButtonBase } from "@mui/material";
import React from "react";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";
import CloseIconWhite from "../../images/svg/close_white";
import Styles from "./processingResultModal.module.css";

const ProcessingResultModal = ({
  name,
  processArticleDone,
  showProcessArticlesModal,
  setShowProcessArticlesModal,
  onArticleTypeClick,
}) => {
  const onBackdropPress = () => {
    setShowProcessArticlesModal({ status: false });
  };

  const btnClick = () => {
    setShowProcessArticlesModal({ status: false });
    onArticleTypeClick({ category: processArticleDone[0] });
  };

  return (
    <div className={Styles.modal}>
      <div className={Styles.container}>
        <div className={Styles.btnClose}>
          <ButtonBase
            onClick={() => {
              onBackdropPress();
            }}
          >
            <CloseIconWhite className={Styles.iconClose} />
          </ButtonBase>
        </div>
        <main className={Styles.content}>
          <h2 className={Styles.textQuestion}>
            {processArticleDone &&
              showProcessArticlesModal.count > 0 &&
              !showProcessArticlesModal.isSameCount && (
                <span>Freshly Processed Content Available</span>
              )}
          </h2>
          <h3 className={Styles.textSimple}>
            {processArticleDone &&
            showProcessArticlesModal.count > 0 &&
            !showProcessArticlesModal.isSameCount ? (
              <span>
                Select a category below to view your fresh content for{" "}
                <strong>{name}</strong>
              </span>
            ) : (
              <span>
                No new News(BETA) content available for <strong>{name}</strong>
              </span>
            )}
          </h3>

          <div className={Styles.btnDiv}>
            {processArticleDone &&
              showProcessArticlesModal.count > 0 &&
              processArticleDone.map((item) => (
                <div
                  onClick={() => {
                    btnClick();
                  }}
                  className={Styles.itemDiv}
                >
                  <span className={Styles.itemTitle}>{item}</span>
                  {getFilterImage(item, true, null, null, true)}
                </div>
              ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProcessingResultModal;
