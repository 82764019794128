import { useState, useEffect } from "react";
import {
  GET_FULLTEXT,
  GET_NOTIFICATION_DETAILS,
  READ_NNOTIFICATION,
  SAVE_ARTICLE_HISTORY,
} from "../../../queries/queries";
import { useLazyQuery, useMutation } from "react-apollo";
import { useNavigate } from "react-router-dom";
import parseSaveHistory from "../../../utils/parseSaveHistoryData";
import { notificationStore } from "../../../store/notificationStore";

let selectRelevantArticle = null;
let contactDataID = null;

const GetNotificationDetails = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [notificationId, setNotificationId] = useState();
  const [isFromDashboard, setFromDashboard] = useState(false);

  const setLoadingClickCard = notificationStore(
    (state) => state.setLoadingClickCard
  );

  const notification = notificationStore((state) => state.notification);
  const setNotification = notificationStore((state) => state.setNotification);

  const setOpenArticle = notificationStore((state) => state.setOpenArticle);

  const [
    getFulltext,
    {
      loading: getFullTextLoading,
      data: getFullTextData,
      error: getFullTextError,
    },
  ] = useLazyQuery(GET_FULLTEXT, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!getFullTextLoading && getFullTextData) {
      setLoadingClickCard(false);
      if (!!getFullTextData) {
        const newArticle = {
          ...selectedItem,
          article: {
            ...selectedItem.article,
            fulltext: getFullTextData.getFulltxt.data || "",
          },
        };
        setSelectedItem(newArticle);
        gotoArticle(newArticle);
      }
    } else if (!getFullTextLoading && getFullTextError) {
      setLoadingClickCard(false);
    }
  }, [getFullTextLoading, getFullTextData, getFullTextError]);

  const [
    get_article_details,
    { loading: articleLoading, data: articleData, error: articleError },
  ] = useLazyQuery(GET_NOTIFICATION_DETAILS, {
    fetchPolicy: "network-only",
  });

  const [saveArticleHistory] = useMutation(SAVE_ARTICLE_HISTORY, {
    onCompleted(data) {},
    onError(ee) {},
  });

  useEffect(() => {
    if (!articleLoading && articleData) {
      setSelectedItem(articleData.getNotificationDetails);
      if (!!articleData.getNotificationDetails.articleId) {
        getFulltext({
          variables: {
            articleId: articleData.getNotificationDetails.articleId,
          },
        });
        saveArticleHistory({
          variables: {
            data: parseSaveHistory(
              articleData.getNotificationDetails,
              null,
              contactDataID
            ),
          },
        });
      } else {
        gotoArticle(articleData.getNotificationDetails);
      }
    } else if (!articleLoading && articleError) {
      setLoadingClickCard(false);
    }
  }, [articleLoading, articleData, articleError]);

  const [readNotification, { data: readNotificationData, error: readNotificationError }] = useMutation(READ_NNOTIFICATION)

  const getArticleDetails = (
    query,
    notificationId,
    isFromDashboard,
    relevantArticle,
    contactId
  ) => {
    contactDataID = contactId;
    selectRelevantArticle = relevantArticle;
    setSelectedItem();
    setNotificationId(notificationId);
    setLoadingClickCard(true);
    setFromDashboard(isFromDashboard);
    get_article_details(query);
  };

  const gotoArticle = (item) => {
    removeNotification(notificationId);
    callReadNotificationApi(notificationId);
    setLoadingClickCard(false);
    setOpenArticle({ status: true, item: item });
  };

  const removeNotification = (notificationId) => {
    let noti = notification?.map((n) => ({
      ...n,
      data: n.data.map((item) => ({
        ...item,
        isRead: item.id == notificationId ? true : item.isRead,
      })),
    }));
    if (noti.length > 0) {
      setNotification(noti);
    }
  };

  const callReadNotificationApi = (notificationId) => {
    if (notificationId)
      readNotification({ variables: { notificationId: [notificationId] } });
  };

  return {
    getArticleDetails,
    callReadNotificationApi,
    readNotificationData,
    readNotificationError
  };
};
export default GetNotificationDetails;
