import { ArrowBack } from '@mui/icons-material';
import { Backdrop, Button, ButtonGroup, CircularProgress, IconButton } from '@mui/material';
import { SectionList } from '@protonko/section-list';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useNavigate } from "react-router-dom";
import { GET_NOTIFICATION, READ_NNOTIFICATION } from '../../queries/queries';
import { notificationStore } from '../../store/notificationStore';
import {
  filterArticlesByRead,
  filterArticlesByUnread,
} from '../../utils/filterUtils';
import Styles from './notification.module.css';
import NotificationList from './notificationList';
import back_white from "../../images/svg/back_white.svg"

const FILTER_TIMEOUT = 2000;
let lastTimeout = null;

const Notification = (props) => {
  const navigate = useNavigate();

  const [getNotification, { data, loading }] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: 'network-only',
  });
  const [readNotification] = useMutation(READ_NNOTIFICATION, {
    onCompleted(data) { },
    onError(dd) { },
  });

  const sections = [
    {
      title: 'Unread',
      content: <div></div>,
    },
    {
      title: 'Read',
      content: <div></div>,
    },
  ];

  const buttons = sections.map((section) => section.title);

  const end = new Date();
  const sectionIndex = notificationStore((state) => state.sectionIndex)
  const setSectionIndex = notificationStore((state) => state.setSectionIndex)

  const notification = notificationStore((state) => state.notification)
  const setNotification = notificationStore((state) => state.setNotification)

  const filteredNotification = notificationStore((state) => state.filteredNotification)
  const setFilteredNotification = notificationStore((state) => state.setFilteredNotification)

  const filteredNotificationExist = notificationStore((state) => state.filteredNotificationExist)
  const setFilteredNotificationExist = notificationStore((state) => state.setFilteredNotificationExist)

  const isChanged = notificationStore((state) => state.isChanged)
  const setChanged = notificationStore((state) => state.setChanged)

  const removeAll = notificationStore((state) => state.removeAll)

  const [refresh, setRefresh] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);

  const backButton = () => {
    removeAll()
    navigate('/dashboard');
  };

  useEffect(() => {
    if (data && !loading) {
      setChanged(false)
      parseNotification(data);
    }
    setRefresh(loading);
  }, [data, loading]);

  const callNotification = () => {
    setRefresh(true);
    getNotification();
  }

  const broadcast = new BroadcastChannel("refresh-notification");
  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        if (lastTimeout) {
          clearTimeout(lastTimeout);
        }
        lastTimeout = setTimeout(() => {
          // To avoid multiple API call once received multiple notification
          callNotification();
        }, FILTER_TIMEOUT);
      };
    }
  }, []);


  useEffect(() => {
    if (notification == null || notification.length == 0) {
      callNotification()
    }
  }, []);


  useEffect(() => {
    setFilteredNotification(
      notification?.map((n) => ({
        ...n,
        data:
          sectionIndex == 0
            ? filterArticlesByUnread(n.data)
            : filterArticlesByRead(n.data),
      })),
    );
  }, [notification, sectionIndex]);

  useEffect(() => {
    let data = [];
    filteredNotification?.map((fN) => {
      data = [...data, ...fN?.data];
    });
    if (data?.length > 0) setFilteredNotificationExist(true);
    else setFilteredNotificationExist(false);
  }, [filteredNotification]);

  const removeNotification = (notificationId) => {
    setNotification(
      notification?.map((n) => ({
        ...n,
        data: n.data.map(item => ({ ...item, isRead: (item.id == notificationId ? true : item.isRead) }))
      })),
    );
  };

  const parseNotification = (data) => {
    if (data && data.getNotifications.length > 0) {
      let noti = [];
      let recent = [];
      let past = [];
      let readIDs = [];
      data.getNotifications.forEach((item) => {
        if (!item.isRead) readIDs.push(item.id);
        const notificationDate = new Date(parseInt(item.createdAt));
        if (moment().diff(notificationDate, 'days') > 3) {
          past.push(item);
        } else {
          recent.push(item);
        }
      });

      if (recent.length > 0) {
        noti.push({ title: 'Recent Notifications', data: recent });
      }
      if (past.length > 0) {
        noti.push({ title: 'Past Notifications', data: past });
      }
      setNotification(noti);
      // if (readIDs.length > 0) {
      //   readNotification({ variables: { notificationId: readIDs } });
      // }
    }
  };


  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.containerHeader} >
          <div className={Styles.header}>
            <div style={{ position: "absolute" }}>
              <IconButton onClick={backButton}>
                <img src={back_white} className="back_white" alt="back_white" />
              </IconButton>
            </div>
            <span className={Styles.headerText}>
              Notifications
            </span>
          </div>
          <div className={Styles.contactsListContainer}>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              className={Styles.segmentedControlContainer}
            >
              <Button
                fullWidth
                className={sectionIndex == 0 ? Styles.segmentedControlSelectedButton : Styles.segmentedControlButton}
                onClick={() => setSectionIndex(0)}>
                <span className={sectionIndex == 0 ? Styles.segmentedControlSelectedText : Styles.segmentedControlText}>
                  {sections[0].title}
                </span>
              </Button>
              <Button
                fullWidth
                className={sectionIndex == 1 ? Styles.segmentedControlSelectedButton : Styles.segmentedControlButton}
                onClick={() => setSectionIndex(1)}>
                <span className={sectionIndex == 1 ? Styles.segmentedControlSelectedText : Styles.segmentedControlText}>
                  {sections[1].title}
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={Styles.contactsListContainer} style={{ paddingTop: 110, }}>
          {filteredNotificationExist ? (
            <div className={Styles.notificationListContainer}>
              <SectionList
                sections={filteredNotification}
                keyExtractor={(item, index) => `${item}_${index}`}
                renderItem={(item) => (
                  <NotificationList
                    isTransparent
                    item={item}
                    setLoadingCard={setLoadingCard}
                    removeNotification={removeNotification}
                    callNotification={callNotification}
                  />
                )}
                renderSectionHeader={(item) => (
                  <div className={Styles.titleSection}>{item.title}</div>
                )}
                showsVerticalScrollIndicator={true}
              />
            </div>
          ) : (
            <div
              className={Styles.contactsListContainer}
              style={{ alignItems: 'center', paddingTop: 64 }}>
              {loading ? (
                <></>
              ) : (
                <>
                  <img
                    src={require('../../images/no-results-icon.png')}
                    className={Styles.image}
                  />
                  <span className={Styles.titleText}>
                    There's nothing here yet…
                  </span>
                  {sectionIndex == 0 ? (
                    <span className={Styles.descriptionText}>
                      Ferret will notify you of any news about your starred
                      contacts. They will be shown here as they come in.
                    </span>
                  ) : (
                    <span className={Styles.descriptionText}>
                      As you read your notifications we will move them here to
                      help keep things organized.
                    </span>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(loadingCard || loading) && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#00000000" }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default Notification;
