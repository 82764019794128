import React, { useState, useEffect, useContext, useRef } from "react";
import createStyle from "./styles";
import { useLazyQuery, useMutation, useSubscription } from "react-apollo";
import {
  CHECK_META_SEARCH_DATA,
  GET_CONFIGURATION,
  SAVE_ADHOC_SEARCH,
  Save_Recent_Search,
  USER_UPDATES,
} from "../../queries/queries";
import SectionListView from "../articleList/SectionListView";
import Variables from "../../utils/Variables";
import useIntialModalData from "../searchModalV2/useIntialModalData";
import useRefineSearch from "../searchModalV2/useRefineSearch";
import { UserContext } from "../../contexts/userContext";
import {
  sortArticles,
  formatArticleAttributes,
  filterArticles,
  returnIntialTags,
} from "../../utils/filterUtils";
import { useNavigate } from "react-router-dom";
import {
  capitalizeCase,
  capitalizeSearchTermsTag,
  getObjectDiff,
  parseDate,
  removeDuplicateTags,
  titleCase,
} from "../../utils/utils";
import {
  AppBar,
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Toolbar,
} from "@mui/material";
import PersoneInfo from "../PersonInfo/personeInfo";
import "./style.css";
import { useLocation } from "react-router-dom";
import { BackWhiteIcon } from "../../images/svg/back_white";
import SearchLimitExceedModal from "../searchLimitExceedModal";
import SubscriptionPlansModal, {
  FREE_PLAN_ID,
} from "../subscriptionPlans/subscriptionPlansModal";
import { ModelType } from "../searchLimitExceedModal/searchLimitExceedModal";
import { savedSearchArticlesStore } from "../../store/savedSearchArticlesStore";
import SaveSearchResultModal from "../searchResults/saveSearchResultModal";
import ProcessingResultModal from "../searchResults/processingResultModal";
import savedSearchData from "../../utils/savedSearchData";
import PDFDownload from "../../images/svg/pdf-download";
import Download from "../../images/svg/download";
import { generatePdf } from "../../utils/pdfGeneratorUtils";
import { searchResultStore } from "../../store/searchResultStore";
import Article from "../../web/components/article/article";
import PersoneInfoWeb from "../../web/components/PersonInfo/personeInfo";
import MapView from "../map/mapView";
import { MapWhiteIcon } from "../../images/svg/map_white";
import SingleBtnAlert from "../alert/singleBtnAlert";

// import SearchLimitExceedModal from '../searchLimitExceedModal';
// import SubscriptionPlansModal, { FREE_PLAN_ID } from '../subscriptionPlans/subscriptionPlansModal';
// import { ModelType } from '../searchLimitExceedModal/searchLimitExceedModal';

let theme;
let clickedFilter = false;
let clearFilterArticleType = false;
let clearFilterItemType = false;
let lastDataSize;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
};
let hasData = false;
let showMetaSearchDialog = false;
let metaAPICount = 0;
let temporaryStatusesOfFilters = null;
let lastQuery = null;
let searchIds = [];

const SavedContactsArticles = () => {
  const { state } = useLocation();
  let props = state ?? {};
  props = JSON.parse(props);
  let callSaveRecentSearchAPI = true;

  const {
    contactItem = {},
    feed = {},
    source = "",
    metadata = {},
    searchTerm = "",
    searchType = "",
    searchContext,
    tahoeId,
    age,
    tracerQueryId,
    requestMetadata,
    navigateFrom = "",
    initialMetadata = {},
    searchList,
    articleDetail,
    openMetaSearch
  } = props;
  const {
    getModalData,
    loadingData,
    error,
    searchSettings,
    getModalDataForMetaTag,
  } = useIntialModalData(metadata, searchType, searchTerm);

  const incomingArticles = [];
  const navigate = useNavigate();
  const style = createStyle();

  const [openMapView, setOpenMapView] = useState()

  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);
  const setOpenMapDashboard = searchResultStore((state) => state.setOpenMapDashboard);

  const editSearchSetting = savedSearchArticlesStore(
    (state) => state.editSearchSetting
  );
  const setEditSearchSetting = savedSearchArticlesStore(
    (state) => state.setEditSearchSetting
  );

  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });

  const [articleSummary, setArticleSummary] = useState("");
  const [showArticleSummary, setShowArticleSummary] = useState(false);

  const [showArticleDetails, setArticleDetails] = useState(false);
  const [openArticleDetail, setOpenArticleDetail] = useState({
    status: false,
    data: {},
  });
  const isRefineApiCalled = useRef(false);

  const originalArticles = savedSearchArticlesStore(
    (state) => state.originalArticles
  );
  const setOriginalArticles = savedSearchArticlesStore(
    (state) => state.setOriginalArticles
  );

  const configurationData = savedSearchArticlesStore(
    (state) => state.configurationData
  );
  const setConfigurationData = savedSearchArticlesStore(
    (state) => state.setConfigurationData
  );

  const ConfigurationLoading = savedSearchArticlesStore(
    (state) => state.ConfigurationLoading
  );
  const setConfigurationLoading = savedSearchArticlesStore(
    (state) => state.setConfigurationLoading
  );

  const filteredArticles = savedSearchArticlesStore(
    (state) => state.filteredArticles
  );
  const setFilteredArticles = savedSearchArticlesStore(
    (state) => state.setFilteredArticles
  );

  const filteredArticles2 = savedSearchArticlesStore(
    (state) => state.filteredArticles2
  );
  const setFilteredArticles2 = savedSearchArticlesStore(
    (state) => state.setFilteredArticles2
  );

  const originalfilteredArticles = savedSearchArticlesStore(
    (state) => state.originalfilteredArticles
  );
  const setOriginalFilteredArticles = savedSearchArticlesStore(
    (state) => state.setOriginalFilteredArticles
  );

  const articleFilterData = savedSearchArticlesStore(
    (state) => state.articleFilterData
  );
  const setArticleFilterData = savedSearchArticlesStore(
    (state) => state.setArticleFilterData
  );

  const personalInfo = savedSearchArticlesStore((state) => state.personalInfo);
  const setPersonalInfo = savedSearchArticlesStore(
    (state) => state.setPersonalInfo
  );

  const isShowPersonalInfo = savedSearchArticlesStore(
    (state) => state.isShowPersonalInfo
  );
  const setShowPersonalInfo = savedSearchArticlesStore(
    (state) => state.setShowPersonalInfo
  );

  const selectedFilter = savedSearchArticlesStore(
    (state) => state.selectedFilter
  );
  const setSelectedFilter = savedSearchArticlesStore(
    (state) => state.setSelectedFilter
  );

  const sortState = savedSearchArticlesStore((state) => state.sortState);
  const setSortState = savedSearchArticlesStore((state) => state.setSortState);

  const showStrongMatchArticles = savedSearchArticlesStore(
    (state) => state.showStrongMatchArticles
  );
  const setShowStrongMatchArticles = savedSearchArticlesStore(
    (state) => state.setShowStrongMatchArticles
  );

  const showWeakMatchArticles = savedSearchArticlesStore(
    (state) => state.showWeakMatchArticles
  );
  const setShowWeakMatchArticles = savedSearchArticlesStore(
    (state) => state.setShowWeakMatchArticles
  );

  const filterAttributes = savedSearchArticlesStore(
    (state) => state.filterAttributes
  );
  const setFilterAttributes = savedSearchArticlesStore(
    (state) => state.setFilterAttributes
  );

  const readFilter = savedSearchArticlesStore((state) => state.readFilter);
  const setReadFilter = savedSearchArticlesStore(
    (state) => state.setReadFilter
  );

  const unreadFilter = savedSearchArticlesStore((state) => state.unreadFilter);
  const setUnreadFilter = savedSearchArticlesStore(
    (state) => state.setUnreadFilter
  );

  const attributes = savedSearchArticlesStore((state) => state.attributes);
  const setAttributes = savedSearchArticlesStore(
    (state) => state.setAttributes
  );

  const headerTags = savedSearchArticlesStore((state) => state.headerTags);
  const setHeaderTags = savedSearchArticlesStore(
    (state) => state.setHeaderTags
  );

  const saveRelevancyData = savedSearchArticlesStore(
    (state) => state.saveRelevancyData
  );
  const setSaveRelevancyData = savedSearchArticlesStore(
    (state) => state.setSaveRelevancyData
  );

  const deleteRelevancyData = savedSearchArticlesStore(
    (state) => state.deleteRelevancyData
  );
  const setDeleteRelevancyData = savedSearchArticlesStore(
    (state) => state.setDeleteRelevancyData
  );

  const verified = savedSearchArticlesStore((state) => state.verified);
  const setVerified = savedSearchArticlesStore((state) => state.setVerified);

  const partial = savedSearchArticlesStore((state) => state.partial);
  const setPartial = savedSearchArticlesStore((state) => state.setPartial);

  const needsReview = savedSearchArticlesStore((state) => state.needsReview);
  const setNeedsReview = savedSearchArticlesStore(
    (state) => state.setNeedsReview
  );

  const refreshList = savedSearchArticlesStore((state) => state.refreshList);
  const setRefreshList = savedSearchArticlesStore(
    (state) => state.setRefreshList
  );

  const showHeader = savedSearchArticlesStore((state) => state.showHeader);
  const setShowHeader = savedSearchArticlesStore(
    (state) => state.setShowHeader
  );

  const modalFilterVisible = savedSearchArticlesStore(
    (state) => state.modalFilterVisible
  );
  const setModalFilterVisible = savedSearchArticlesStore(
    (state) => state.setModalFilterVisible
  );

  const summaryPosition = savedSearchArticlesStore(
    (state) => state.summaryPosition
  );
  const setSummaryPosition = savedSearchArticlesStore(
    (state) => state.setSummaryPosition
  );

  const articlePosition = savedSearchArticlesStore(
    (state) => state.articlePosition
  );
  const setArticlePosition = savedSearchArticlesStore(
    (state) => state.setArticlePosition
  );

  const articleCount = savedSearchArticlesStore((state) => state.articleCount);
  const setArticleCount = savedSearchArticlesStore(
    (state) => state.setArticleCount
  );

  const akaList = savedSearchArticlesStore((state) => state.akaList);
  const setAKAlist = savedSearchArticlesStore((state) => state.setAKAlist);

  const recentSearchId = savedSearchArticlesStore(
    (state) => state.recentSearchId
  );
  const setRecentSearchId = savedSearchArticlesStore(
    (state) => state.setRecentSearchId
  );

  const articles = savedSearchArticlesStore((state) => state.articles);
  const setArticles = savedSearchArticlesStore((state) => state.setArticles);

  const applyFilter = savedSearchArticlesStore((state) => state.applyFilter);
  const setApplyFilter = savedSearchArticlesStore(
    (state) => state.setApplyFilter
  );

  const refreshing = savedSearchArticlesStore((state) => state.refreshing);
  const setRefreshing = savedSearchArticlesStore(
    (state) => state.setRefreshing
  );

  const refreshingFilter = savedSearchArticlesStore(
    (state) => state.refreshingFilter
  );
  const setRefreshingFilter = savedSearchArticlesStore(
    (state) => state.setRefreshingFilter
  );

  const isSearchLimitExceed = savedSearchArticlesStore(
    (state) => state.isSearchLimitExceed
  );
  const setSearchLimitExceed = savedSearchArticlesStore(
    (state) => state.setSearchLimitExceed
  );

  const searchLimitExceedTitle = savedSearchArticlesStore(
    (state) => state.searchLimitExceedTitle
  );
  const setSearchLimitExceedTitle = savedSearchArticlesStore(
    (state) => state.setSearchLimitExceedTitle
  );

  const openPersonalInfo = savedSearchArticlesStore(
    (state) => state.openPersonalInfo
  );
  const setOpenPersonalInfo = savedSearchArticlesStore(
    (state) => state.setOpenPersonalInfo
  );

  const subscriptionModalVisible = savedSearchArticlesStore(
    (state) => state.subscriptionModalVisible
  );
  const setSubscriptionModalVisible = savedSearchArticlesStore(
    (state) => state.setSubscriptionModalVisible
  );

  const linkedInData = savedSearchArticlesStore((state) => state.linkedInData);
  const setLinkedInData = savedSearchArticlesStore(
    (state) => state.setLinkedInData
  );

  const feedbackOptions = savedSearchArticlesStore(
    (state) => state.feedbackOptions
  );
  const setFeedbackOptions = savedSearchArticlesStore(
    (state) => state.setFeedbackOptions
  );

  const profilePic = savedSearchArticlesStore((state) => state.profilePic);
  const setProfilePic = savedSearchArticlesStore(
    (state) => state.setProfilePic
  );

  const processArticleDone = savedSearchArticlesStore(
    (state) => state.processArticleDone
  );
  const setProcessArticleDone = savedSearchArticlesStore(
    (state) => state.setProcessArticleDone
  );

  const processArticleInProgress = savedSearchArticlesStore(
    (state) => state.processArticleInProgress
  );
  const setProcessArticleInProgress = savedSearchArticlesStore(
    (state) => state.setProcessArticleInProgress
  );

  const showSaveConfirmationAlert = savedSearchArticlesStore(
    (state) => state.showSaveConfirmationAlert
  );
  const setShowSaveConfirmationAlert = savedSearchArticlesStore(
    (state) => state.setShowSaveConfirmationAlert
  );

  const chkBxNotifyUser = savedSearchArticlesStore(
    (state) => state.chkBxNotifyUser
  );
  const setChkBxNotifyUser = savedSearchArticlesStore(
    (state) => state.setChkBxNotifyUser
  );

  const monitorSearch = savedSearchArticlesStore(
    (state) => state.monitorSearch
  );
  const setMonitorSearch = savedSearchArticlesStore(
    (state) => state.setMonitorSearch
  );

  const saveSearch = savedSearchArticlesStore((state) => state.saveSearch);
  const setSaveSearch = savedSearchArticlesStore(
    (state) => state.setSaveSearch
  );

  const showProcessArticlesModal = savedSearchArticlesStore(
    (state) => state.showProcessArticlesModal
  );
  const setShowProcessArticlesModal = savedSearchArticlesStore(
    (state) => state.setShowProcessArticlesModal
  );
  const [isPDFGenerate, setPDFGenerate] = useState(false);

  const [ferretAI, setFerretAI] = useState(false)
  const [ferretAISummary, setFerretAISummary] = useState()
  const [educationMore, setEducationMore] = useState(false)
  const [experienceMore, setExperienceMore] = useState(false)
  const [showOthers, setShowOthers] = useState({})
  const [showMore, setShowMore] = useState(false);

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(0);

  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );


  let isFirstOpen = false;
  const { user, setUser } = useContext(UserContext);
  const [getConfigData, { loading: configLoading, data: configData }] =
    useLazyQuery(GET_CONFIGURATION, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setConfigurationData(data);
        setConfigurationLoading(configLoading);
      },
      onError: (err) => {
        console.log(err, "err");
      },
    });

  const broadcast = new BroadcastChannel("background-linkedin-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        updateLinkedInView(res.data.data);
      };
    }
  }, []);

  const { data: apolloSubscriptionData, error: apolloSubscriptionError } =
    useSubscription(USER_UPDATES);

  useEffect(() => {
    if (apolloSubscriptionData) {
      updateLinkedInView(apolloSubscriptionData.userUpdates);
    } else if (apolloSubscriptionError) {
      console.error("apolloSubscriptionError", apolloSubscriptionError);
    }
  }, [apolloSubscriptionData, apolloSubscriptionError]);

  const updateLinkedInView = (payload) => {
    if (
      payload?.type === "linkedin-profile-match" &&
      payload?.potentialMatchId === Variables.potentialMatchId
    ) {
      if (payload?.linkedinProfile) {
        let data = JSON.parse(payload?.linkedinProfile);
        let jsonData = data && data.length > 0 && JSON.parse(data);
        setProfilePic(jsonData?.profile_picture_src);
        if (jsonData) {
          jsonData.education = jsonData.education?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
          jsonData.experience = jsonData.experience?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
        }
        setLinkedInData(jsonData);
      }
    }
  };

  useEffect(() => {
    if (searchSettings) setEditSearchSetting(searchSettings);
  }, [searchSettings]);

  useEffect(() => {
    if (akaList) {
      let categoryFilterData = getCategoryFilterData();
      if (
        categoryFilterData?.Places ||
        categoryFilterData?.Organizations ||
        categoryFilterData?.People ||
        categoryFilterData?.Parties ||
        categoryFilterData?.Attorneys
      ) {
        setAttributes(
          returnIntialTags(
            categoryFilterData?.Places,
            categoryFilterData?.Organizations,
            searchSettings,
            akaList,
            categoryFilterData?.People,
            categoryFilterData?.Parties,
            categoryFilterData?.Attorneys
          )
        );
      }
    }
  }, [akaList]);

  const {
    refineSearch,
    data,
    loading,
    error: errorRefineSearch,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
    getArticleDetailsFromRefineSearch,
    getArticleFilterData,
    getArticleTypeCount,
    mapSingleArticle,
    getPersonalInfo,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId,
    getLinkedInData,
    getFeedbackOptions,
    getProfilePic,
    getSearchId,
  } = useRefineSearch();

  const handlePressEditSearch = () => {
    const data = {
      isRecentSearch: false,
      fromEditSearch: true,
      isEdit: true,
      searchType: searchType,
      searchSettings: editSearchSetting,
      isAutoFocus: false,
      cleanStateOnBlur: false,
      contactID: contactItem?.id,
      mPhoneNumberInput: searchContext?.phone,
      mCountryInput: searchContext?.country,
      mCityInput: capitalizeCase(searchContext?.city),
      mStateInput: searchContext?.state,
      mAgeInput: searchContext?.age?.toString() ?? "",
      mQueryData: searchContext,
      mSavedTracerQueryId: tracerQueryId,
      mSavedTahoeId: tahoeId,
      navigatedFrom: "SavedSearches",
      oldMetaValue: requestMetadata,
      recentSearchId: contactItem?.recentSearchID ?? recentSearchId,
      irrelevantArticleId: contactItem?.recentSearchID ?? recentSearchId,
      searchList: searchList,
    };
    navigate("/searchComponent", { state: data });
  };

  const navigateToDashboard = () => {
    setSearchLimitExceed(false);
    if (!openPersonalInfo) {
      navigate(-1, { replace: true });
    } else {
      setOpenPersonalInfo(false);
      openResultScreen();
    }
  };

  useEffect(() => {
    if (!loading && errorRefineSearch) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Did not connect to server. Please try again.",
      });
    }
  }, [errorRefineSearch, loading])

  useEffect(() => {
    setShowPersonalInfo(!searchList);
    if (!searchList || !articleDetail) {
      setShowHeader(true);
    } else if (searchList || articleDetail) {
      setShowHeader(false);
    }
  }, [searchList, articleDetail]);

  useEffect(() => {
    if (isShowPersonalInfo) {
      setShowHeader(true);
    }
  }, [isShowPersonalInfo]);

  useEffect(() => {
    // getConfigData()
    if (
      (originalArticles == undefined || originalArticles?.length == 0) &&
      !isRefineApiCalled.current
    ) {
      isRefineApiCalled.current = true;
      fetchArticlesTypeByRiskRanking();

      getModalData(
        searchContext?.phone,
        searchContext?.country,
        searchContext?.city,
        searchContext?.state,
        searchContext?.age
      );

      const query = {
        variables: {
          fullname: {
            search: "search",
            meta_data: JSON.stringify(requestMetadata),
            contactId: contactItem?.id,
            source: "ADHOC_SEARCH",
          },
        },
      };

      if (contactItem?.id) query.variables.fullname.contactId = contactItem?.id;
      if (tahoeId && tahoeId != "") query.variables.fullname.tahoeId = tahoeId;
      if (tracerQueryId && tracerQueryId != "")
        query.variables.fullname.tracerQueryId = tracerQueryId;
      lastQuery = query;
      refineSearch(query);
    }
  }, []);

  useEffect(() => {
    if (refreshList == true) {
      setRefreshList(false);

      let meta_data = { ...requestMetadata };
      if (
        saveRelevancyData?.Organizations &&
        saveRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company && meta_data.Company != ""
            ? meta_data.Company +
            ";" +
            saveRelevancyData?.Organizations?.join(";")?.toString()
            : saveRelevancyData?.Organizations?.join(";")?.toString();
      }
      if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place && meta_data.Place != ""
            ? meta_data.Place +
            ";" +
            saveRelevancyData?.Places?.join(";")?.toString()
            : saveRelevancyData?.Places?.join(";")?.toString();
      }

      if (
        deleteRelevancyData?.Organizations &&
        deleteRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company &&
          meta_data.Company != "" &&
          meta_data.Company.split(";");

        meta_data.Company = meta_data?.Company?.filter((item) => {
          return !deleteRelevancyData?.Organizations?.includes(item);
        })
          .join(";")
          ?.toString();
      }
      if (deleteRelevancyData?.Places && deleteRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place &&
          meta_data.Place != "" &&
          meta_data.Place.split(";");

        meta_data.Place = meta_data?.Place?.filter((item) => {
          return !deleteRelevancyData?.Places?.includes(item);
        })
          .join(";")
          ?.toString();
      }

      const query = {
        variables: {
          fullname: {
            search: "search",
            meta_data: JSON.stringify(meta_data),
            contactId: contactItem?.id,
            source: "ADHOC_SEARCH",
            refresh: true
          },
        },
      };

      if (contactItem?.id) query.variables.fullname.contactId = contactItem?.id;
      if (tahoeId && tahoeId != "") query.variables.fullname.tahoeId = tahoeId;
      if (tracerQueryId && tracerQueryId != "")
        query.variables.fullname.tracerQueryId = tracerQueryId;

      const name =
        feed?.searchSettings?.initialSearchString ?? feed?.fullName ?? "";
      const searchType =
        meta_data?.Person && meta_data?.Person != ""
          ? "PERSON"
          : meta_data?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : meta_data?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : meta_data?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : meta_data?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON";
      getModalDataForMetaTag(searchType, meta_data, name);

      refineSearch(query);
    }
  }, [refreshList]);

  useEffect(() => {
    async function fetchData() {
      reloadScreenData();
    }
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = "";
        const mmArticle = filteredArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        originalArticles = originalArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        setFilteredArticles(formatArticleAttributes(mmArticle));
        filterArticleTypeCount();
        const countArticle = {
          highRisk: articleCount?.highRisk - (riskCategory == "high" ? 1 : 0),
          news: articleCount?.news - (riskCategory == "low" ? 1 : 0),
          alerts: articleCount?.alerts - (riskCategory == "medium" ? 1 : 0),
        };
        setArticleCount(countArticle);
        Variables.articleIdMarkNo = false;
      }, 200);
    }
  };

  const fetchArticlesTypeByRiskRanking = () => {
    const riskLevel = Variables.riskLevelsStatuses;
    const riskLevelFilter = [];
    if (riskLevel) {
      if (riskLevel?.high) {
        riskLevelFilter.push("HIGH");
      }
      if (riskLevel?.low) {
        riskLevelFilter.push("LOW");
      }
      if (riskLevel?.medium) {
        riskLevelFilter.push("MEDIUM");
      }
    }
  };

  useEffect(() => {
    if (applyFilter) onHandleRefresh(true);
  }, [applyFilter]);

  useEffect(() => {
    if (!!data && !loading) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        setOpenPersonalInfo(false);
        setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
        setSearchLimitExceed(true);
        const updateUserInfo = { ...user, searchLimitExceed: true };
        setUser(updateUserInfo);
      } else {
        let currentMonthAdhocSearchCount =
          user.adhocSearchLimitPerMonth -
          data?.refine_search?.currentMonthAdhocSearchCount;
        let divideByThree = parseInt(user.adhocSearchLimitPerMonth / 3);
        if (
          currentMonthAdhocSearchCount == divideByThree ||
          currentMonthAdhocSearchCount < 3
        ) {
          setSearchLimitExceed(true);
          setOpenPersonalInfo(true);
          switch (true) {
            case currentMonthAdhocSearchCount === divideByThree:
              setSearchLimitExceedTitle(
                `You Have ${currentMonthAdhocSearchCount} Free Searches Left This Month`
              );
              break;
            case currentMonthAdhocSearchCount === 2:
              setSearchLimitExceedTitle(
                `You Have 2 Searches Left This Month`
              );
              break;
            case currentMonthAdhocSearchCount === 1:
              setSearchLimitExceedTitle(
                `You Have 1 Free Searches Left This Month`
              );
              break;
            case currentMonthAdhocSearchCount === 0:
              setOpenPersonalInfo(false);
              setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
              const updateUserInfo = { ...user, searchLimitExceed: true };
              setUser(updateUserInfo);
            default:
              break;
          }
        } else {
          openResultScreen();
        }
      }
    }
  }, [data, loading]);

  const openResultScreen = () => {
    if (Array.isArray(data?.refine_search?.article_details)) {
      searchIds = getSearchId();
      Variables.potentialMatchId = getPotentialMatchId();
      Variables.contactId = getContactId();
      const readArticles = getReadArticlesIds();
      const articleDetails = getArticleDetailsFromRefineSearch();
      const parsedArticles = articleDetails
        .filter((item) => {
          return item;
        })
        .map((item) => mapSingleArticle(item, searchTerm, readArticles)); // filter null article
      setOriginalArticles(parsedArticles);
      setArticleFilterData(getArticleFilterData);
      filterArticleTypeCount(
        data?.refine_search?.filterData?.filter((item) => item.count)
      );
      setFilteredArticles(parsedArticles);
      if (akaList == undefined)
        setAKAlist(
          getAKAList()?.map((item) => {
            return { param: item, aka: true };
          }) ?? []
        );
      setArticleCount(getArticleTypeCount);
      if (refreshing || refreshingFilter) {
        setRefreshing(false);
        setRefreshingFilter(false);
        const latestArticles = parsedArticles;
        lastDataSize = latestArticles.length;
        setArticles(latestArticles);
      } else {
        const latestArticles = parsedArticles;
        lastDataSize = latestArticles.length;
        const newArticles = articles.concat(latestArticles);
        setArticles(newArticles);
      }
      setPersonalInfo(getPersonalInfo());
      setLinkedInData(getLinkedInData());
      setFeedbackOptions(getFeedbackOptions());
      setProfilePic(getProfilePic());
      if (openMetaSearch) {
        onArticleTypeClick("Metasearch")
      }
    } else if (
      !loading &&
      data &&
      Array.isArray(data?.refine_search?.article_details) &&
      data?.refine_search?.article_details?.length == 0
    ) {
    }

    callSaveRecentSearch();

    if (!showMetaSearchDialog) {
      callGetMetaSearchData();
    }

    if (showMetaSearchDialog && processArticleDone.length > 0) {
      const metaSearchArray = articleFilterData?.filter(
        (item) => item.category == "News"
      );
      setShowSaveConfirmationAlert({ status: false, msg: "" });
      setShowProcessArticlesModal({
        status: true,
        count: (metaSearchArray && metaSearchArray?.[0]?.count) ?? 0,
      });
      showMetaSearchDialog = false;
    }
  };

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };

  const getSelectedParentCategory = () => {
    let categoryType = [];
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.status) {
          categoryType = [...categoryType, type?.label];
        } else if (type.count && type.count > 0) isFirstOpen = true;
      });
    }
    return categoryType;
  };

  const filterAction = (isRiskRankingApplied) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([]);
      return;
    }

    const selectedParentCategory = getSelectedParentCategory();

    let filterByArticleType = [...originalArticles];
    filterByArticleType = filterByArticleType.filter(
      (item) => riskLevelsStatuses[item?.riskCategory?.toLowerCase()]
    );

    if (
      selectedParentCategory &&
      (selectedParentCategory.length || isFirstOpen)
    ) {
      filterByArticleType = filterByArticleType.filter(
        (item) =>
          selectedParentCategory.includes(item.parentCategory) ||
          selectedParentCategory.includes("All")
      );
    }

    if (!isRiskRankingApplied) {
      setFilteredArticles(formatArticleAttributes(filterByArticleType));
    } else {
      filterArticleTypeCount();
    }
  };

  const filterArticleTypeCount = (data) => {
    const Filterdata = articleFilterData ? articleFilterData : data;
    if (Filterdata) {
      let mArticleFilter = [...Filterdata];
      const updatedArray = mArticleFilter.map((itemType) => {
        return {
          ...itemType,
          count: filterArticles(
            showStrongMatchArticles,
            showWeakMatchArticles,
            readFilter,
            unreadFilter,
            filterAttributes,
            originalArticles,
            itemType?.category
          ).filter(
            (itemArticle) =>
              itemType?.category === (itemArticle?.parentCategory ?? null) &&
              isRiskCategorySelected(itemArticle?.riskCategory?.toLowerCase())
          ).length,
        };
      });
      setArticleFilterData(updatedArray);
    }
  };

  useEffect(() => {
    if (!showArticleDetails) {
      if (isShowPersonalInfo) {
        window.scrollTo(0, summaryPosition);
        setArticlePosition(0);
      } else {
        window.scrollTo(0, articlePosition);
      }
    }
  }, [showArticleDetails, isShowPersonalInfo]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isShowPersonalInfo]);

  useEffect(() => {
    return () => {
      temporaryStatusesOfFilters = null;
      hasData = false;
      showMetaSearchDialog = false;
      metaAPICount = 0;
      localStorage.setItem("reload", false);
    };
  }, []);

  const [
    saveAdhocSearch,
    { data: saveData, loading: saveLoading, error: saveError },
  ] = useMutation(SAVE_ADHOC_SEARCH);

  useEffect(() => {
    if (!saveLoading && (saveData || saveError)) {
      goBack();
    }
  }, [saveData, saveLoading, saveError]);

  const saveMySearches = () => {
    let saveSearchVariables = savedSearchData(
      metadata,
      searchTerm,
      recentSearchId,
      null,
      contactItem?.id,
      tracerQueryId,
      tahoeId,
      searchContext,
      akaList
    );
    saveSearchVariables.data.searchIds = searchIds;
    saveSearchVariables.data.notifyUser = chkBxNotifyUser;
    saveSearchVariables.data.monitorSearch = monitorSearch;
    saveSearchVariables.data.saveSearch = monitorSearch ? true : saveSearch;
    if (personalInfo?.personalSummary?.estimatedAge) {
      saveSearchVariables.data.age =
        personalInfo?.personalSummary?.estimatedAge;
    }

    saveAdhocSearch({
      variables: saveSearchVariables,
    });
    Variables.isAddSearches = true;
  };

  const [
    checkMetaSearch,
    {
      loading: checkMetaSearchLoading,
      data: checkMetaSearchData,
      error: checkMetaSearchError,
    },
  ] = useLazyQuery(CHECK_META_SEARCH_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!checkMetaSearchLoading && checkMetaSearchData) {
      let data = checkMetaSearchData.checkMetaSearchData;
      switch (true) {
        case data.hasData == true && data.refresh == false:
          callGetMetaSearchAfter10sec(data);
          break;
        case data.hasData == true && data.refresh == true:
          callGetMetaSearchAfter10sec(data);
          onGetMetaSearchResponse(false);
          break;
        case data.hasData == false && data.refresh == false:
          onGetMetaSearchResponse(true);
          break;
      }
      metaAPICount++;
    }
  }, [checkMetaSearchLoading, checkMetaSearchData]);

  const onGetMetaSearchResponse = (finalOutputReceive) => {
    if (finalOutputReceive) {
      hasData = false;
      setProcessArticleDone(processArticleInProgress);
      setProcessArticleInProgress([]);
    }
    if (metaAPICount > 0) {
      showMetaSearchDialog = true;
      var query = JSON.parse(JSON.stringify(lastQuery));
      query.variables.fullname.refresh = true
      refineSearch(query);
    }
  };

  const callGetMetaSearchAfter10sec = (data) => {
    if (!hasData) hasData = data.hasData;

    setProcessArticleInProgress(data.source);
    setProcessArticleDone(data.source);
    setTimeout(() => {
      callGetMetaSearchData();
    }, 10000);
  };

  const callGetMetaSearchData = () => {
    checkMetaSearch({
      variables: {
        searchIds: searchIds,
      },
    });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0 && !showArticleDetails) {
      if (isShowPersonalInfo) {
        setSummaryPosition(position);
      } else {
        setArticlePosition(position);
      }
    }
  };

  const isRiskCategorySelected = (category) => {
    const riskLevel = Variables.riskLevelsStatuses;
    if (category && riskLevel?.[category]) {
      return true;
    } else {
      return false;
    }
  };

  const onHandleRefresh = (clearData = false) => {
    clickedFilter = true;
    const variables = {
      chunkSize: 50,
    };
    if (applyFilter) {
      variables.articleType = clearFilterArticleType
        ? []
        : applyFilter.seletedArticleType;
      variables.itemType = clearFilterItemType
        ? []
        : applyFilter.seletedItemType;
      variables.riskCode = clearFilterItemType
        ? []
        : applyFilter.seletedRiskType;
    }

    setRefreshing(!clearData);
    setRefreshingFilter(clearData);
    if (clearData) {
      setArticles([]);
    }
  };

  const onBackPress = () => {
    if (searchList) {
      navigate(-1, { replace: true });
      return;
    }
    if (!isShowPersonalInfo) {
      setShowPersonalInfo(true);
      return;
    }

    const isMetaDataSimilar = getObjectDiff(metadata, initialMetadata);
    if (isMetaDataSimilar.length && navigateFrom !== "watchedList") {
      setShowSaveConfirmationAlert({ status: true, msg: feed?.fullName });
    } else {
      goBack();
    }
  };

  const goBack = () => {
    Variables.potentialMatchId = null;
    Variables.contactId = null;
    navigate(-1);
  };

  useEffect(() => {
    let tags = [
      ...(feed?.searchSettings?.initialSearchParams ?? searchParams),
      ...headerTags,
    ];
    if (akaList) tags?.splice(1, 0, ...akaList);
    if (personalInfo?.personalSummary?.estimatedAge)
      tags?.splice(
        1,
        0,
        ...[
          {
            type: "age",
            param: `Est Age ${personalInfo?.personalSummary?.estimatedAge}`,
          },
        ]
      );
    tags = [...new Set(tags)];
    setHeaderTags(removeDuplicateTags(tags));
  }, [akaList]);

  const [save_Article, { loading: loadingSaveSeaches }] = useMutation(
    Save_Recent_Search,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        setRecentSearchId(data.saveRecentSearch.id);
      },
      onError(error) { },
    }
  );

  const callSaveRecentSearch = () => {
    if (!callSaveRecentSearchAPI) {
      return;
    }
    callSaveRecentSearchAPI = false;
    let variables = {
      data: {
        // "contactId": contactItem.id,
        searchTerm: searchTerm.trim(),
        searchType: searchType ?? "PERSON",
        eventType: "potential-match-search",
        metaData: {},
        searchContext: {},
      },
    };

    if (contactItem?.akaList) {
      variables.data.akaList = contactItem.akaList;
    }

    if (metadata) {
      if (metadata.Person) {
        variables.data.metaData.Person = [metadata.Person];
      }
      if (metadata.Company) {
        variables.data.metaData.Company = [metadata.Company];
      }
      if (metadata.Place) {
        variables.data.metaData.Place = [metadata.Place];
      }
      if (metadata.Location) {
        variables.data.metaData.Location = [metadata.Location];
      }
      if (metadata.Nationality) {
        variables.data.metaData.Nationality = [metadata.Nationality];
      }
    }

    if (searchContext) {
      if (searchContext.age) {
        variables.data.searchContext.age = searchContext.age;
      }
      if (searchContext.city) {
        variables.data.searchContext.city = searchContext.city;
      }
      if (searchContext.country) {
        variables.data.searchContext.country = searchContext.country;
      }
      if (searchContext.entityType) {
        variables.data.searchContext.entityType = searchContext.entityType;
      }
      if (searchContext.phone) {
        variables.data.searchContext.phone = searchContext.phone;
      }
      if (searchContext.state) {
        variables.data.searchContext.state = searchContext.state;
      }
      if (searchContext.title) {
        variables.data.searchContext.title = searchContext.title;
      }
    }

    if (tahoeId) {
      variables.data.tahoeId = tahoeId;
    }
    if (tracerQueryId) {
      variables.data.tracerQueryId = tracerQueryId;
    }

    if (age) {
      variables.data.age = age;
    }

    if (contactItem?.recentSearchID)
      variables.data.id = contactItem.recentSearchID;

    if (Variables.potentialMatchId)
      variables.data.uuid = Variables.potentialMatchId;

    save_Article({
      variables: variables,
    });
  };

  let searchParams = Object.values(metadata);

  const Header = (props) => {
    const { style } = props;
    const headerRightStyle = style.headerRight;
    const headerLeftStyle = style.headerLeft;
    const profilePictureStyle = style.profilePicture;

    return (
      <AppBar
        component="nav"
        position="fixed"
        style={headerLeftStyle.container}
      >
        <div style={style.headerContainer}>
          <div style={style.leftHeaderContainer}>
            <div style={style.backStyle} onClick={onBackPress}>
              <BackWhiteIcon />
            </div>
            {/* <div>
              {feed.profilePictureUri && (
                <img
                  src={{ uri: userInfo.profilePictureUri }}
                  style={profilePictureStyle}
                />
              )}
              {!feed.profilePictureUri && (
                <DefaultAvatar style={profilePictureStyle} />
              )}
            </div> */}
            <span style={{ ...headerLeftStyle.fullName }} numberOfLines={2}>
              {capitalizeCase(
                feed?.searchSettings?.initialSearchString ??
                feed?.fullName ??
                "",
                true
              )}
            </span>
          </div>

          {source == "" && (
            <div style={headerRightStyle.container}>

              <button
                style={{ marginRight: 8 }}
                onClick={() => {
                  setOpenMapView(true)
                  setOpenMapDashboard(true)
                }}
              >
                <MapWhiteIcon />
              </button>

              <ButtonBase onClick={handlePressEditSearch}>
                <div style={headerRightStyle.editSearchButton}>
                  <span style={headerRightStyle.editSearchText}>
                    Edit Search
                  </span>
                </div>
              </ButtonBase>
              {/* <Settings
                fillColor={headerRightStyle.settings.fillColor}
                style={headerRightStyle.settings}
              /> */}
            </div>
          )}
        </div>
        <div
          id="savedContactArticlesHeaderTags"
          style={{ ...style.flatlistContainer }}
        >
          {headerTags.map((item, index) => {
            let customItemStyle = {};
            if (!item.param && !item.includes("App.") && !item.includes("y/o"))
              customItemStyle = { textTransform: "capitalize" };
            return item?.aka ? (
              <div style={style.tagListContainer}>
                <span
                  style={{
                    ...style.tagListTitle,
                    fontWeight: "600",
                    color: "#949494",
                  }}
                >
                  {"AKA "}
                  <span style={style.tagListTitle}>{item.param}</span>
                </span>
              </div>
            ) : item?.type == "age" ? (
              <div style={style.tagListContainer}>
                <span
                  style={{
                    ...style.tagListTitle,
                    fontWeight: "600",
                    color: "#949494",
                  }}
                >
                  <span style={style.tagListTitle}>{item.param}</span>
                </span>
              </div>
            ) : item ? (
              <div style={style.tagListContainer}>
                <span style={style.tagListTitle}>
                  {capitalizeSearchTermsTag(item, index)}
                </span>
              </div>
            ) : (
              <></>
            );
          })}
        </div>
      </AppBar>
    );
  };

  const onFilterCompleted = (
    riskLevel,
    filterData,
    isRiskRankingApplied = false
  ) => {
    riskLevelsStatuses = riskLevel;
    temporaryStatusesOfFilters = filterData;
    let applyFilter = false;
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses?.low ||
      !riskLevelsStatuses?.medium
    ) {
      applyFilter = true;
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    filterAction(isRiskRankingApplied);
    const selectedParentCategory = getSelectedParentCategory();
    if (!selectedParentCategory.includes(selectedFilter)) {
      window.scrollTo(0, 0);
    }
  };

  //sorting articles
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  //filtering articles
  useEffect(() => {
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles,
        getSelectedParentCategory()
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  useEffect(() => {
    if (openMapDashboard && isShowPersonalInfo) {
      setOpenMapView(openMapDashboard)
    }
  }, [isShowPersonalInfo])

  //filtering for article count
  useEffect(() => {
    setOriginalFilteredArticles(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        originalArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    originalArticles,
  ]);

  const onArticleTypeClick = (data) => {
    setSelectedFilter(data.category);
    if (isShowPersonalInfo) {
      // If you are on personal info screen
      // setShowPersonalInfo(false);
      navigate("/savedContactsArticlesList", {
        state: JSON.stringify({ ...props, searchList: true }),
      });
    } else {
      // Other cases, Article detail screen
      setShowHeader(true);
      setOpenArticleDetail({ status: false, data: {} });
      let mArticleTypeCount = [...articleFilterData];
      setArticleFilterData([]);
      setTimeout(() => {
        setArticleFilterData(mArticleTypeCount);
      }, 1);
    }
  };

  const handleGeneratePDF = () => {
    setIsPdfGenerating(true);
    setPDFGenerate(true);
    setTimeout(() => {
      let fullName =
        capitalizeCase(
          feed?.searchSettings?.initialSearchString ??
          feed?.fullName ??
          "",
          true
        )
      generatePdf(
        openArticleDetail?.status ? 'article-content-div' : 'content-to-pdf',
        fullName,
        () => {
          setIsPdfGenerating(false);
          setPDFGenerate(false);
        })
    }, 500);
  };

  return (
    <div style={{ ...style.container }}>
      {showHeader && <Header style={style} />}
      {(loading) && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#00000000",
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        component="main"
        style={{
          ...style.contactsListContainer,
          marginTop: showHeader ? 36 : 0,
        }}
      >
        {/* <FilterHeader
          totalArticle={filteredArticles?.length}
          isEnableFilter={isEnableFilter}
          handlePressFilter={handlePressFilter}
          modalFilterVisible={modalFilterVisible}
          isFilterApplied={isFilterApplied }
        /> */}
        <div style={style.contactsListContainerBody}>
          {/* {isNoResult && (!isFilterApplied && filteredArticles.length == 0) && <LoadFeedAnimation
          finishAnimation={animationFinished}
          onFinishAnimation={() => {
            setAnimationFinished(true)
          }}
          style={{marginHorizontal: 10, marginBottom: 20,}}
          articleLoading={loading}
          isNoResult={isNoResult}
        />} */}

          {originalArticles && isShowPersonalInfo && personalInfo ? (
            <>
              <PersoneInfo
                setOpenMapView={(isOpen) => {
                  setOpenMapView(isOpen)
                  setOpenMapDashboard(isOpen)
                }}
                profileName={
                  searchSettings?.initialSearchString ??
                  feed.searchSettings?.initialSearchString ??
                  feed.fullName ??
                  ""
                }
                linkedInData={linkedInData}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                personalInfo={personalInfo}
                onClick={onArticleTypeClick}
                feedbackOptions={feedbackOptions}
                profilePic={profilePic}
                setProfilePic={setProfilePic}
                processArticleInProgress={processArticleInProgress}
                potentialMatchId={Variables.potentialMatchId}
                setPDFWebFerretAI={setFerretAI}
                setPDFWebFerretAISummary={setFerretAISummary}
                setPDFEducationMore={setEducationMore}
                setPDFExperienceMore={setExperienceMore}
                setPDFShowOthers={setShowOthers}
                setPDFShowMore={setShowMore}
              />
              {isPDFGenerate && <PersoneInfoWeb
                profileName={
                  searchSettings?.initialSearchString ??
                  feed.searchSettings?.initialSearchString ??
                  feed.fullName ??
                  ""
                }
                linkedInData={linkedInData}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                personalInfo={personalInfo}
                onClick={onArticleTypeClick}
                processArticleInProgress={processArticleInProgress}
                feedbackOptions={feedbackOptions}
                profilePic={profilePic}
                setProfilePic={setProfilePic}
                potentialMatchId={Variables.potentialMatchId}
                PDFFerretAISummary={ferretAISummary}
                PDFFerretAI={ferretAI}
                educationPDFMore={educationMore}
                experiencePDFMore={experienceMore}
                showPDFOthers={showOthers}
                showPDFMore={showMore}
              />}
            </>
          ) : (
            <SectionListView
              recentSearchId={recentSearchId}
              contactDataID={contactItem?.id}
              selectedParentCategory={getSelectedParentCategory()}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              isShowPersonalInfo={isShowPersonalInfo}
              type={source === "shared-contacts" ? "inTheNews" : ""}
              isVisible={modalFilterVisible}
              onFilterCompleted={onFilterCompleted}
              articleType={articleFilterData}
              articleCount={articleCount}
              articleRelevacnyData={JSON.stringify(props)}
              articles={sortArticles(filteredArticles2, sortState)}
              articlesLoading={loading}
              style={style}
              refreshing={refreshing}
              onScrollBeginDrag={() => (clickedFilter = false)}
              articleSource={"SEARCH"}
              noBtnPressDelay={true}
              isShowName={false}
              savedContactID={contactItem?.id}
              reloadScreenData={reloadScreenData}
              configData={configurationData}
              configLoading={ConfigurationLoading}
              sortState={sortState}
              setSortState={setSortState}
              showStrongMatchArticles={showStrongMatchArticles}
              setShowStrongMatchArticles={setShowStrongMatchArticles}
              showWeakMatchArticles={showWeakMatchArticles}
              setShowWeakMatchArticles={setShowWeakMatchArticles}
              filterAttributes={filterAttributes}
              setFilterAttributes={setFilterAttributes}
              readFilter={readFilter}
              setReadFilter={setReadFilter}
              unreadFilter={unreadFilter}
              setUnreadFilter={setUnreadFilter}
              attributes={attributes}
              setAttributes={setAttributes}
              setHeaderTags={setHeaderTags}
              headerTags={headerTags}
              saveRelevancyData={saveRelevancyData}
              setSaveRelevancyData={setSaveRelevancyData}
              deleteRelevancyData={deleteRelevancyData}
              setDeleteRelevancyData={setDeleteRelevancyData}
              setRefreshList={setRefreshList}
              setShowHeader={setShowHeader}
              feed={{
                ...feed,
                ...{ potentialMatchId: Variables.potentialMatchId },
                searchListContacts: searchList,
                articleDetail: articleDetail,
              }}
              verified={verified}
              setVerified={setVerified}
              partial={partial}
              setPartial={setPartial}
              needsReview={needsReview}
              setNeedsReview={setNeedsReview}
              processingArticle={hasData}
              setArticleDetails={setArticleDetails}
              openArticleDetail={openArticleDetail}
              setOpenArticleDetail={setOpenArticleDetail}
              savedSearchProps={props}
              setPDFIsSummaryExpanded={setIsSummaryExpanded}
              setPDFSectionIndex={setSectionIndex}
              handleGeneratePDF={handleGeneratePDF}
              articleSummary={articleSummary}
              setArticleSummary={setArticleSummary}
              showArticleSummary={showArticleSummary}
              setShowArticleSummary={setShowArticleSummary}
            />
          )}
        </div>
      </Box>
      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          title={searchLimitExceedTitle}
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={navigateToDashboard}
          modelType={ModelType.SEARCH_LIMIT_MODAL}
        />
      )}

      {showSaveConfirmationAlert.status && (
        <SaveSearchResultModal
          setShowSaveConfirmationAlert={setShowSaveConfirmationAlert}
          name={titleCase(showSaveConfirmationAlert.msg)}
          cancelBtnClick={goBack}
          setChkBxNotifyUser={setChkBxNotifyUser}
          chkBxNotifyUser={chkBxNotifyUser}
          setSaveSearch={setSaveSearch}
          saveSearch={saveSearch}
          setMonitorSearch={setMonitorSearch}
          monitorSearch={monitorSearch}
          saveMySearches={saveMySearches}
          showNotifyUser={hasData}
        />
      )}

      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}


      {showProcessArticlesModal.status && (
        <ProcessingResultModal
          processArticleDone={processArticleDone}
          name={titleCase(feed?.fullName)}
          showProcessArticlesModal={showProcessArticlesModal}
          setShowProcessArticlesModal={setShowProcessArticlesModal}
          onArticleTypeClick={onArticleTypeClick}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
      {true && openArticleDetail && openArticleDetail.status &&
        <div style={{ display: isPDFGenerate ? "block" : "none", zIndex: -100, position: "absolute" }}>
          <Article
            isPDFSummaryExpanded={isSummaryExpanded}
            sectionPFDIndex={sectionIndex}
            articleSummary={articleSummary}
            showArticleSummary={showArticleSummary}
            {...openArticleDetail.data} />
        </div>
      }

      {(originalArticles && isShowPersonalInfo && personalInfo) &&
        <div onClick={handleGeneratePDF} style={style.floatingCircle}>
          <PDFDownload />
          <Download />
        </div>
      }
      {openMapView && (
        <MapView
          onFilterCompleted={(filter) => {
            onFilterCompleted(riskLevelsStatuses, filter, false);
          }}
          articleFilterData={articleFilterData}
          articles={filteredArticles}
          setOpenMapView={setOpenMapView}
          setOpenMapDashboard={setOpenMapDashboard}
          configData={configurationData}
          configLoading={ConfigurationLoading}
          onArticleTypeClick={onArticleTypeClick}
          reloadScreenData={reloadScreenData}
          articleSource={"SEARCH"}
          searchTerm={searchTerm}
          savedContactID={contactItem?.id}
          recentSearchId={recentSearchId}
          contactData={contactItem}
          setRefreshList={setRefreshList}
        />
      )}
    </div>
  );
};

export default SavedContactsArticles;