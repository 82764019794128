import React, { useState, useEffect } from "react";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";
import createStyle from "./styles";
import Styles from "./cardItem.module.css";
import ArticleCategoriesTooltip from "../../../components/onboardingTooltips/articleCategoriesTooltip";
import { ButtonBase } from "@mui/material";
import InfoGreen from "../../../images/svg/info-green";
import { articleTooltipStore } from "../../../store/articleTooltipStore";

const CardItem = (props) => {
  const { item, isClickable } = props;
  const style = createStyle();
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);
  const isTooltipOpen = articleTooltipStore((state) => state.isTooltipOpen);
  const setTooltipOpen = articleTooltipStore((state) => state.setTooltipOpen);



  useEffect(() => {
    if (isTooltipOpen) {
      setTooltipOpen(false)
      setVisibleTooltip(false)
    }
  }, [isTooltipOpen])

  return (
    <div style={style.cardDiv}>
      <div style={style.itemView}>
        {getFilterImage(item.category, isClickable)}
      </div>
      <div
        style={{
          whiteSpace: "pre-line",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={
            !isClickable
              ? { color: "#8E96A8", ...style.filterLable }
              : { ...style.filterLable }
          }
        >
          {item.category === "News" ? (
            <>
              {item.category} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
            </>
          ) : (
            `${item.category} (${item.count})`
          )}
          <ArticleCategoriesTooltip
            style={{ display: 'inline' }}
            category={item.category}
            isVisible={isVisibleTooltip}
            setIsVisible={setVisibleTooltip}
            onClick={() => {
              setTooltipOpen(false);
              setVisibleTooltip(false);
            }}
          >
            <ButtonBase
              style={{ paddingLeft: 4 }}
              onClick={() => {
                setTooltipOpen(true);
                setTimeout(() => {
                  setVisibleTooltip(true);
                }, 100);
              }}
            >
              <InfoGreen />
            </ButtonBase>
          </ArticleCategoriesTooltip>
        </span>
      </div>
    </div>
  );
};
export default CardItem;
