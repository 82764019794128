import React, { useState, useContext, useEffect } from "react";
import createStyle from "./styles";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";
import TickGreen from "../../../images/svg/tick-green";
import QuestionMarkGrey from "../../../images/svg/question-mark-grey";
import ExclamationMarkGrey from "../../../images/svg/exclamation-mark-grey";
import { ButtonBase, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import ProcessingArticlesTooltip from "../../../components/onboardingTooltips/processingArticlesTooltip";
import Styles from "./cardItemDataCategory.module.css";
import { UserContext } from "../../../contexts/userContext";
import UnpaidMemberPopupModal from "../../../components/unpaidMemberModal/unpaidMemberModal";
import SubscriptionPlansModal from "../../../components/subscriptionPlans/subscriptionPlansModal";
import LockCross from "../../../images/svg/lock-cross";
import ArticleCategoriesTooltip from "../../../components/onboardingTooltips/articleCategoriesTooltip";
import InfoGreen from "../../../images/svg/info-green";
import { articleTooltipStore } from "../../../store/articleTooltipStore";
import { searchResultStore } from "../../../store/searchResultStore";

const CardItemDataCategory = (props) => {
  const { item, isClickable, onClick, verified, partial, needsReview, index } = props;
  const style = createStyle();
  const { user } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);
  const isTooltipOpen = articleTooltipStore((state) => state.isTooltipOpen);
  const setTooltipOpen = articleTooltipStore((state) => state.setTooltipOpen);

  const setSelectedfilterIndex = searchResultStore((state) => state.setSelectedfilterIndex);

  const renderRow = (iconType, text, count) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          paddingTop: 4,
          paddingBottom: 4,
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            width: 20,
            marginRight: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          {iconType === "tick" && <TickGreen />}
          {iconType === "questionMark" && <QuestionMarkGrey />}
          {iconType === "exclamationMark" && <ExclamationMarkGrey />}
        </span>
        <span
          className={Styles.text}
          style={{
            ...style.itemLabel,
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "flex-start",
            alignSelf: "center",
          }}
        >
          {text}
        </span>
        <span
          style={{
            ...style.itemLabel,
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "flex-start",
            alignSelf: "center",
          }}
        >
          {" " + count}
        </span>
      </div>
    );
  };

  useEffect(()=>{
    if(isTooltipOpen){
      setTooltipOpen(false)
      setVisibleTooltip(false)
      setIsVisible(false)
    }
  },[isTooltipOpen])

  return (
    <div className={Styles.gridCard}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            whiteSpace: "pre-line",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={
              !isClickable
                ? { color: "#8E96A8", ...style.filterLable }
                : { ...style.filterLable }
            }
          >
            {item.category === "News" ? (
              <>
                {item.category} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
              </>
            ) : (
                `${item.category}`
            )}
            {item.count > 0 && (
              <span
                style={{
                  ...style.filterLable,
                  marginLeft: 4,
                  color: isClickable ? "#8E96A8" : "#b5b9c4",
                }}
              >
                {" (" + item.count + ")"}
              </span>
            )}
            <ArticleCategoriesTooltip
              style={{display: 'inline'}}
              category={item.category}
              isVisible={isVisibleTooltip}
              setIsVisible={setVisibleTooltip}
              onClick={() => {
                setTooltipOpen(false)
                setVisibleTooltip(false)
              }}
              > 
             <ButtonBase
                style={{ paddingLeft: 4 }}
                onClick={() => {
                  setTooltipOpen(true)
                  setTimeout(()=>{
                    setVisibleTooltip(true)
                  }, 100)
                }}
              >
                <InfoGreen />
              </ButtonBase>
          </ArticleCategoriesTooltip>
          </span>        
        </div>
        {item.isProcessing && item.count > 0 && (
          <>
            <ProcessingArticlesTooltip
              setIsVisible={()=>{
                setTooltipOpen(false)
                setIsVisible(false)
              }}
              isVisible={isVisible}
            >
              <div onClick={() => {
                setTooltipOpen(true)
                setTimeout(()=>{
                  setIsVisible(true)
                }, 100)
                }}>
                <Box
                  style={{ marginLeft: 8, marginTop: 8, marginRight: 16 }}
                  sx={{
                    width: 24,
                    "& .MuiLinearProgress-colorPrimary": {
                      backgroundColor: "#91CB6C",
                    },
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#EFF1F5",
                    },
                  }}
                >
                  <LinearProgress style={{ borderRadius: 16 }} />
                </Box>
              </div>
            </ProcessingArticlesTooltip>
          </>
        )}
      </div>

      {(item.category === "Potential Criminal Records" && !user?.isSubscribed) ? (
        <button
          onClick={() => setUnpaidMemberModal(true)}
          className={`${Styles.itemViewData} ${Styles.processingView}`}
        >
          <LockCross /> 
        </button>
      ) : item.isProcessing && item.count == 0 ? ( 
        <>
          <ProcessingArticlesTooltip
            setIsVisible={setIsVisible}
            isVisible={isVisible}
          >
            <ButtonBase
              onClick={() => setIsVisible(true)}
              style={{ ...style.itemViewData, ...style.processingView }}
            >
              <span>Processing...</span>

              <Box
                style={{ marginLeft: 16 }}
                sx={{
                  width: 30,
                  "& .MuiLinearProgress-colorPrimary": {
                    backgroundColor: "#91CB6C",
                  },
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#EFF1F5",
                  },
                }}
              >
                <LinearProgress style={{ borderRadius: 16 }} />
              </Box>
            </ButtonBase>
          </ProcessingArticlesTooltip>
        </>
      ) : (
        <ButtonBase
          style={{ ...style.itemViewData }}
          onClick={() => {
            setSelectedfilterIndex(index)
            onClick?.(item)
          }}
          disabled={!isClickable}
        >
          <div
            style={{ flex: 3, alignItems: "center", justifyContent: "center" }}
          >
            {verified && renderRow("tick", "Verified", item.verified)}
            {partial && renderRow("questionMark", "Partial", item.partial)}
            {needsReview &&
              renderRow("exclamationMark", "Needs Review", item.needs_review)}
          </div>

          <div
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 6,
            }}
          >
            {getFilterImage(item.category, isClickable)}
          </div>
        </ButtonBase>
      )}
      {unpaidMemberModal && (
        <UnpaidMemberPopupModal
          onBackdropPress={() => {
            setUnpaidMemberModal(false);
          }}
          upgradeNow={()=>{
            setSubscriptionModalVisible(true);
          }}
        />
      )}
      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
    </div>
  );
};
export default CardItemDataCategory;
