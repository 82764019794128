import { ButtonBase } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from "../../contexts/onboardingContext";
import { GET_NOTIFICATION } from '../../queries/queries';
import NotificationList from '../notificationList/notificationList';
import NotificationTooltip from '../onboardingTooltips/notificationTooltip';
import NotificationBanner from './notificationBanner';
import createStyle from './styles';

const FILTER_TIMEOUT = 2000
let lastTimeout = null

const NotificationListView = ({ onOpenNotification }) => {
  const style = createStyle();
  const [notification, setNotification] = useState()
  const [loadingCard, setLoadingCard] = useState(false)
  const [getNotification, { data }] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: 'network-only'
  })

  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));

  const broadcast = new BroadcastChannel('refresh-notification');

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        if (lastTimeout) {
          clearTimeout(lastTimeout);
        }
        lastTimeout = setTimeout(() => {
          // To avoid multiple API call once received multiple notification
          callNotification();
        }, FILTER_TIMEOUT);
      };
    }
  }, []);

  useEffect(() => {
    if (data) {
      setNotification(data.getNotifications)
    }
  }, [data])

  useEffect(() => {
    callNotification()
  }, [])

  const callNotification = () => {
    getNotification({
      variables: {
        showRead: false,
        limit: 3
      }
    })
  }

  return (
    <>
      <>
        {(onboardingState === OnboardingStates.notificationBanner
          || onboardingState === OnboardingStates.notificationBannerEnd) && (
            <NotificationTooltip onClick={() => changeOnboardingState()}>
              <NotificationBanner isTooltip={true} onOpenNotification={onOpenNotification} />
            </NotificationTooltip>
          )}
        {(onboardingState != OnboardingStates.notificationBanner
          && onboardingState != OnboardingStates.notificationBannerEnd) && ((notification && notification.length == 0) || notification == undefined) && <NotificationBanner onOpenNotification={onOpenNotification} />}
        {(onboardingState != OnboardingStates.notificationBanner
          && onboardingState != OnboardingStates.notificationBannerEnd) && notification && notification.length > 0 &&
          <>
            <span style={style.titleMain}>Notifications</span>
            <div style={style.viewBanner}>
              <div style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  borderStyle: 'solid',
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderColor: '#EFF1F5'
                }}>
                <NotificationList
                  item={notification[0]}
                  setLoadingCard={setLoadingCard}
                  isFromDashboard={true}
                callNotification={callNotification}
                />
                {notification.length > 1 &&
                  <NotificationList
                    isShowBorder={false}
                    item={notification[1]}
                    setLoadingCard={setLoadingCard}
                    isFromDashboard={true}
                callNotification={callNotification}
                  />
                }
              </div>
              <ButtonBase style={{ alignItems: 'center' }} onClick={(e) => {
                e.stopPropagation()
                onOpenNotification()
              }}>
                <span style={style.buttonNotifi}>{notification.length > 2 ? 'Show More Notifications' : 'Show Past Notifications'}</span>
              </ButtonBase>
            </div>
          </>
        }
      </>
      {/* }
      {loadingCard && <ActivityIndicator
        size="large"
        color={theme.custom.activityIndicator.color}
        style={style.centeredContentView}
      />} */}
    </>
  )
}

export default NotificationListView;