import React from "react";
import createStyle from "./styles";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";
import Styles from "./checkFilter.module.css";

const CheckFilterRecent = (props) => {
  const { index, label, status, setStatus, count = 0 } = props;
  const style = createStyle();

  return (
    <div
      className={count > 0 ? Styles.containerFilter : Styles.filterViewDisable}
      style={{ borderColor: status ? "#078167" : "#EFF1F5" }}
      onClick={count > 0 ? setStatus : null}
      key={index}
    >
      <div className={Styles.mainCategory}>
        <div className={Styles.categoryView}>
          {getFilterImage(label, true, "", null, { transform: "scale(.55)" })}
          <span style={{ ...style.lableText, color: "#142030" }}>
            {label === "News" ? (
              <>
                {label} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
              </>
            ) : (
              label
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CheckFilterRecent;
