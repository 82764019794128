import React from 'react';
import createStyle from './styles';
import { getFilterImage } from '../../../images/filterColorIcon/getFilterImage'

const CheckFilter = (props) => {
  const { index, label, status, iconType, setStatus, count = '', scrollRef } = props;
  const style = createStyle();
  let disabled = false;

  // if(count!=='' && count == 0) disabled = true
  return (
    <div
      onClick={setStatus}
      key={index}
      ref={ref => (scrollRef.current[index] = ref)}
    >
      <div
        style={style.container}
      >
        {getFilterImage(label, status, iconType, { transform: "scale(.55)" })}
        <span style={(!disabled && status) ? { ...style.lableText, color: '#142030' } : { ...style.lableText }}>
          {label === "News" || label === "MetaSearch" ? (
            <>
              News <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span> ({count})
            </>
          ) : (
            <>
              {label} ({count})
            </>
          )}
        </span>
      </div>
      <div style={(!disabled && status) ? { borderBottomColor: '#078167', borderBottomWidth: 2, borderBottomStyle: "solid", marginLeft: 10 } : {}}></div>
    </div >
  )
}

export default CheckFilter;

