import React, { useState, useEffect, useContext } from "react";
import NotificationBanner from "./notificationBanner";
import NotificationList from "../notificationList/notificationList";
import createStyle from "./styles";
import { useLazyQuery } from "react-apollo";
import { GET_NOTIFICATION } from "../../../queries/queries";
import { ButtonBase } from "@mui/material";
import Styles from "./notificationListView.module.css";
import notificationIcon from "../../images/svg/notification-icon.svg";
import { notificationStore } from "../../../store/notificationStore";
import moment from "moment";

const FILTER_TIMEOUT = 2000;
let lastTimeout = null;

const NotificationListView = ({ onOpenNotification }) => {
  const setNotificationCount = notificationStore(
    (state) => state.setNotificationCount
  );

  const notification = notificationStore((state) => state.notification);
  const setNotification = notificationStore((state) => state.setNotification);

  const [notificationList, setNotificationList] = useState();
  const [loadingCard, setLoadingCard] = useState(false);
  const [getNotification, { data }] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setNotificationCount(data?.getNotifications?.length);
      parseNotification(data);
    }
  }, [data]);

  const parseNotification = (data) => {
    if (data && data.getNotifications.length > 0) {
      let noti = [];
      let recent = [];
      let past = [];
      let readIDs = [];
      data.getNotifications.forEach((item) => {
        if (!item.isRead) readIDs.push(item.id);
        const notificationDate = new Date(parseInt(item.createdAt));
        if (moment().diff(notificationDate, "days") > 3) {
          past.push(item);
        } else {
          recent.push(item);
        }
      });

      if (recent.length > 0) {
        noti.push({ title: "Recent Notifications", data: recent });
      }
      if (past.length > 0) {
        noti.push({ title: "Past Notifications", data: past });
      }
      setNotification(noti);
    } else {
      setNotification([]);
    }
  };

  useEffect(() => {
    let notificationArrData = [];
    notification.map((item) => {
      item.data.length > 0 &&
        item.data.map((data) => {
          if (!data.isRead) {
            notificationArrData.push(data);
          }
        });
    });
    setNotificationList(notificationArrData);
  }, [notification]);

  const broadcast = new BroadcastChannel("refresh-notification");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(() => {
          callNotification();
        }, FILTER_TIMEOUT);
      };
    }
  }, []);

  useEffect(() => {
    callNotification();
  }, []);

  const callNotification = () => {
    getNotification({
      variables: {
        showRead: false,
      },
    });
  };

  return (
    <>
      {notificationList && notificationList.length > 0 ? (
        <div className={Styles.containerMain}>
          <div className={Styles.container}>
            <div className={Styles.containerTitle}>
              <img src={notificationIcon} alt="notification-logo" />
              <span className={Styles.titleMain}>Notifications</span>
            </div>
            <ButtonBase
              style={{ alignItems: "center" }}
              onClick={(e) => {
                e.stopPropagation();
                onOpenNotification();
              }}
            >
              <span className={Styles.buttonNotifi}>
                {notificationList.length > 2 ? "View all" : "View all"}
              </span>
            </ButtonBase>
          </div>
          <div>
            <NotificationList
              item={notificationList[0]}
              setLoadingCard={setLoadingCard}
              isFromDashboard={true}
              callNotification={callNotification}
            />
            {notificationList.length > 1 && (
              <NotificationList
                isShowBorder={false}
                item={notificationList[1]}
                setLoadingCard={setLoadingCard}
                isFromDashboard={true}
                callNotification={callNotification}
              />
            )}
          </div>
        </div>
      ) : (
        <NotificationBanner onOpenNotification={onOpenNotification} />
      )}
    </>
  );
};

export default NotificationListView;
