import React, { useState, useEffect } from 'react';
import { DefaultAvatar } from '../../images/svg/default-avatar';
import moment from 'moment-timezone'
import getNotificationDetails from './getNotificationDetails'
import SaveArticleRelevancy from '../articleList/saveArticleRelevancy';
import { useNavigate } from "react-router-dom";
import { GET_RECENT_SEARCH_BY_ID } from '../../queries/queries';
import { useLazyQuery } from 'react-apollo';
import getMetaFromNotification from '../../utils/getMetaFromNotification';
import { savedSearchArticlesStore } from '../../store/savedSearchArticlesStore';
import Styles from './notification.module.css'
import { Avatar } from '@mui/material';
import { searchResultStore } from '../../store/searchResultStore';
import Markdown from "react-markdown";

const NotificationList = ({ isTransparent, item, setLoadingCard, isFromDashboard = false, removeNotification, isShowBorder = true, callNotification }) => {
  const navigate = useNavigate();

  const date = moment(parseInt(item.createdAt)).fromNow();
  const removeAll = savedSearchArticlesStore((state) => state.removeAll)

  const { relevantArticle } = SaveArticleRelevancy();

  const { loadingDone, getArticleDetails, callReadNotificationApi, readNotificationData,
    readNotificationError } =
    getNotificationDetails();

  const [getRecentSearchById, { loading: recentSearchLoading, data: recentSearchData, error: recentSearchError }] = useLazyQuery(GET_RECENT_SEARCH_BY_ID, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!recentSearchLoading && recentSearchData) {
      removeAll?.()
      const itemData = recentSearchData?.getRecentSearchById
      const {
        metadata,
        searchTerm,
        searchType,
        searchContext,
        tahoeId,
        tracerQueryId,
        requestMetadata,
      } = getMetaFromNotification(itemData)
      let arrayData = []
      Object.values(metadata)?.forEach(item => {
        arrayData = [...arrayData, ...item?.split(';')]
      })
    
      if (searchContext?.age) {
        let age = `App. ${searchContext?.age} y/o`
        arrayData.splice(1, 0, age + '')
      }
      if (searchContext?.state)
        arrayData.splice(1, 0, searchContext?.state)
      if (searchContext?.city)
        arrayData.splice(1, 0, searchContext?.city)
      if (searchContext?.country)
        arrayData.splice(1, 0, searchContext?.country)
      if (searchContext?.phone)
        arrayData.splice(1, 0, searchContext?.phone)
      if (searchContext?.stateAbbr)
        arrayData.splice(1, 0, searchContext?.stateAbbr)
      if (searchContext?.countryAbbr)
        arrayData.splice(1, 0, searchContext?.countryAbbr)
    
      // parseSaveRecentSearch(searchTerm, metadata, 'adhoc-search')
      const data = {
        contactItem: {
          ...itemData,
          recentSearchID: itemData?.id,
          id: itemData?.contact,
        },
        metadata,
        searchTerm,
        searchType,
        searchContext,
        tahoeId,
        tracerQueryId,
        requestMetadata,
        openMetaSearch: true,
        feed: {
          fullName: itemData.searchTerm,
          metadata: [],
          searchSettings: { initialSearchParams: arrayData }
        }
      }
      navigate('/savedContactsArticles', { state: JSON.stringify(data) })
    }
  }, [recentSearchLoading, recentSearchData])

  useEffect(() => {
    setLoadingCard?.(loadingDone || recentSearchLoading);
  }, [loadingDone, recentSearchLoading]);

  useEffect(() => {
    if (readNotificationData && !readNotificationError) {
      callNotification()
    }
  }, [readNotificationData, readNotificationError])

  const onCardClick = () => {
    if (item?.type === "Metasearch") {
      callReadNotificationApi(!item.isRead ? item.id : false);
      getRecentSearchById({
        variables: {
          recentSearchId: item.recentSearchId
        }
      })
      return;
    }
    if (item?.type == "Updates") {
      callReadNotificationApi(!item.isRead ? item.id : false);
      return
    }
    item?.osArticleId &&
      getArticleDetails(
        {
          variables: {
            articleId: item?.osArticleId,
            contactId: item?.contactId,
          },
        },
        !item.isRead ? item.id : false,
        isFromDashboard,
        relevantArticle,
        removeNotification,
        item?.contactId
      );
  }
  return (
    <div
      className={Styles.viewBanner}
      style={
        isTransparent
          ? { backgroundColor: "transparent", borderBottomWidth: isShowBorder ? 1 : 0 }
          : { borderBottomWidth: isShowBorder ? 1 : 0 }
      }
      onClick={onCardClick}
    >
      {item && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {item.contactImage ?
            <Avatar
              src={item.contactImage}
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                backgroundColor: "#FFF",
                position: "inherit"
              }}
            >
              <DefaultAvatar className={Styles.profilePicture} />
            </Avatar>
            :
            <DefaultAvatar className={Styles.profilePicture} />
          }
          <div className={Styles.textView}>
            <span className={Styles.title}>
              {(item.contactName ?? item.title) + " "}
            </span>
            <Markdown
              className={`${Styles.itemBody}`}
              children={item.body}
            />
          </div>
        </div>
      )}
      {item && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 40,
            marginTop: 6,
          }}
        >
          {item?.category?.length > 0 && <span className={Styles.time}>{item.category}</span>}
          {item?.category?.length > 0 && <span className={Styles.time}>{'.'}</span>}
          <span className={Styles.time}>{date}</span>
        </div>
      )}
    </div>
  );
}

export default NotificationList;