import React, { useEffect, useState, useRef, useContext } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../contexts/userContext";
import { searchResultStore } from "../../../store/searchResultStore";
import { useLocation, useNavigate } from "react-router-dom";
import formatUserName from "../../../utils/formatUserName";
import Styles from "./SavedSearch.module.css";
import { useMutation } from "react-apollo";
import useRefineSearch from "../../../components/searchModalV2/useRefineSearch";
import {
  Save_Recent_Search,
  GET_POTENTIAL_MATCHES,
} from "../../../queries/queries";
import useQuestions from "../../../components/searchModalV2/useQuestions";
import {
  getMetadataFromQuestions,
  getSavedSearchParams,
} from "../../../components/searchModalV2/questionsController";
import useIntialModalData from "../../../components/searchModalV2/useIntialModalData";
import Variables from "../../../utils/Variables";
import { capitalizeCase, titleCase } from "../../../utils/utils";
import {
  FREE_PLAN_ID,
} from "../../../components/subscriptionPlans/subscriptionPlansModal";
import { searchFormStore } from "../../../store/searchFormStore";
import SearchLimitExceedModal from "../../../components/searchLimitExceedModal";
import { ModelType } from "../../../components//searchLimitExceedModal/searchLimitExceedModal";
import LoadingBar from "../../components/loadingBar/locadingBar";
import SearchResultsWithQuery from "../../components/searchResultsWithQuery/searchResultsWithQuery";
import {
  SAVE_ADHOC_SEARCH,
  GET_FERRET_CONTACT_LIST,
  GET_SAVED_SEARCHES,
} from "../../../queries/queries";
import AlphabetScroll from "../../components/alphabetScroll/alphabetScroll";
import { ButtonBase, Checkbox, InputAdornment, TextField } from "@mui/material";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import { closestContactsStore } from "../../../store/closestContactsStore";
import PotentialMatch from "../../../components/potentialMatch/potentialMatch";
import SavedSearchesList from "../../components/savedSearchesList/savedSearchesList";
import CheckFilterRecent from "../../components/savedSearchesList/checkFilter/checkFilterRecent";
import SearchFiter from "../../../images/svg/searchfilter";
import CloseBt from "../../../images/svg/close.svg";
import ContactImportAlert from "../../components/noContactsAlert/ContactImportAlert";
import ResetIcon from "../../images/icons/buttons/reset.svg";
import SaveSearchResultModal from "../../components/searchResult/saveSearchResultModal";
import { checkCompanyInMetaData } from "../../../components/addOrgModal/checkCompanyInMetaData";
import LocalFilterList from "../../../utils/getCategoryFilterList";
import addQueryData from "../../../utils/addQueryData";
import _ from "lodash";
import { preferredStore } from "../../../store/preferredStore";
import ColoredLinearProgress from "../../../components/loadingBar/ColoredLinearProgress";
import { WhiteBgButtonGreenBorder } from "../../../components/buttons/whiteBgButtonGreenBorder";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import SingleBtnAlert from "../../../components/alert/singleBtnAlert";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
const REFRESH_TIMEOUT = 2000;
let lastTimeout = null;
let timer = null;

const SavedSearch = ({ isDesktop }) => {
  const location = useLocation();
  let props = location.state ?? {};
  const navigate = useNavigate();

  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const removeAll = searchResultStore((state) => state.removeAll);
  const selectedPotentialMatchData = searchFormStore(
    (state) => state.selectedPotentialMatchData
  );
  const removeAllSearchForm = searchFormStore((state) => state.removeAll);

  const setOldPageName = savedSearchesStore((state) => state.setOldPageName);

  const openMapDashboard = searchResultStore(
    (state) => state.openMapDashboard
  );
  const setOpenMapDashboard = searchResultStore(
    (state) => state.setOpenMapDashboard
  );

  const savedSearchPosition = savedSearchesStore(
    (state) => state.savedSearchPosition
  );
  const setSavedSearchPosition = savedSearchesStore(
    (state) => state.setSavedSearchPosition
  );

  const fromMultipleSavedSearch = savedSearchesStore(
    (state) => state.fromMultipleSavedSearch
  );
  const setFromMultipleSavedSearch = savedSearchesStore(
    (state) => state.setFromMultipleSavedSearch
  );

  const selectedPage = savedSearchesStore((state) => state.selectedPage);
  const setSelectedPage = savedSearchesStore((state) => state.setSelectedPage);

  const searchValue = savedSearchesStore((state) => state.searchValue);
  const setSearchValue = savedSearchesStore((state) => state.setSearchValue);

  const setPreferred = preferredStore((state) => state.setPreferred);
  const setSelectedItem = preferredStore((state) => state.setSelectedItem);
  const selectedItem = preferredStore((state) => state.selectedItem);

  const isPdfGenerating = searchResultStore(
    (state) => state.isPdfGenerating
  );
  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  const IS_WATCHED_SEARCH =
    location.pathname == "/watchedSearch" ||
    location.pathname == "/closestContacts";

  const saveSearchVariables = searchResultStore(
    (state) => state.saveSearchVariables
  );
  const setSelectedFilter = savedSearchesStore(
    (state) => state.setSelectedFilter
  );
  const hasData = searchResultStore((state) => state.hasData);

  const [showSaveConfirmationAlert, setShowSaveConfirmationAlert] = useState({
    status: false,
    msg: "",
  });
  const [filterReset, setFilterReset] = useState(false);
  const [chkBxNotifyUser, setChkBxNotifyUser] = useState(false);
  const [saveSearch, setSaveSearch] = useState(false);
  const [monitorSearch, setMonitorSearch] = useState(false);
  const availableFilterList = savedSearchesStore(
    (state) => state.availableFilterList
  );

  const {
    inNewSearch = false,
    fromBackSearch = false,
    isEdit = false,
    mPhoneNumberInput,
    mCityInput,
    mStateInput,
    mAgeInput = "",
    mQueryData,
    mSavedTracerQueryId,
    age,
    mSavedTahoeId,
    navigatedFrom = "",
    recentSearchId,
    searchSettings: {
      initialSearchString = "",
      intitialMetadata = {},
      qaMetaData = {},
      initialSearchParams = [],
    } = {},
  } = props;

  const mCountryInput =
    props?.isEdit || fromBackSearch
      ? props?.mCountryInput && props?.mCountryInput != ""
        ? props.mCountryInput
        : ""
      : "United States";
  const {
    refineSearch,
    data,
    loading,
    error,
    getArticleDetailsFromRefineSearch,
    getArticleFilterData,
    getPersonalInfo,
    getArticleTypeCount,
    mapSingleArticle,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId,
    getSearchId,
    getLinkedInData,
    getFeedbackOptions,
    getProfilePic,
  } = useRefineSearch();

  const [questions, setQuestions] = useState([]);
  const { removeIdontKnowLabel } = useQuestions([], []);

  const contacts = savedSearchesStore((state) => state.contacts);

  const googleCheck = savedSearchesStore((state) => state.googleCheck);
  const setGoogleCheck = savedSearchesStore((state) => state.setGoogleCheck);

  const phoneCheck = savedSearchesStore((state) => state.phoneCheck);
  const setPhoneCheck = savedSearchesStore((state) => state.setPhoneCheck);

  const savedCheck = savedSearchesStore((state) => state.savedCheck);
  const setSavedCheck = savedSearchesStore((state) => state.setSavedCheck);

  const iCloudCheck = savedSearchesStore((state) => state.iCloudCheck);
  const setICloudCheck = savedSearchesStore((state) => state.setICloudCheck);

  const providers = savedSearchesStore((state) => state.providers);
  const setProviders = savedSearchesStore((state) => state.setProviders);

  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });

  const refsList = useRef();
  const refsListLI = useRef();
  const refs = useRef([]);
  const refsViewOnlyList = useRef([]);
  const refAlpha = useRef([]);

  const handleChange = (text) => {
    setSearchValue(text);
  };

  const handleCancel = () => {
    if (searchValue && searchValue.length > 0)
      refAlpha?.current?.setSelectedAlphabetScroll("");
    setSearchValue("");
  };

  useEffect(() => {
    if (!loading && error) {
      stopProgressBar()
      setShowSingleBtnAlert({
        status: true,
        msg: "Did not connect to server. Please try again.",
      });
    }
  }, [error, loading])

  useEffect(() => {
    if (googleCheck && phoneCheck && savedCheck && iCloudCheck)
      setProviders([
        "Google",
        "Personal Contacts",
        "Saved on Ferret",
        "iCloud",
      ]);
    else {
      let providerLocal = [];
      if (googleCheck) providerLocal.push("Google");
      if (phoneCheck) providerLocal.push("Personal Contacts");
      if (savedCheck) providerLocal.push("Saved on Ferret");
      if (iCloudCheck) providerLocal.push("iCloud");
      setProviders(providerLocal);
    }
  }, [googleCheck, phoneCheck, savedCheck, iCloudCheck]);

  const closestCategories = closestContactsStore((state) => state.categories);
  const setClosestCategories = closestContactsStore(
    (state) => state.setCategories
  );

  const savedStoreCategories = savedSearchesStore((state) => state.categories);
  const setSavedStoreCategories = savedSearchesStore(
    (state) => state.setCategories
  );

  const closetSearches = closestContactsStore((state) => state.contactList);
  const setClosetSearches = closestContactsStore(
    (state) => state.setContactList
  );

  const savedStoreSearches = savedSearchesStore((state) => state.savedSearches);
  const setSavedStoreSearches = savedSearchesStore(
    (state) => state.setSavedSearches
  );

  const totalImportCount = closestContactsStore(
    (state) => state.totalImportCount
  );
  const setTotalImportCount = closestContactsStore(
    (state) => state.setTotalImportCount
  );

  const [forceRefresh, setForceRefresh] = useState(false);

  const isProcessRefresh = savedSearchesStore(
    (state) => state.isProcessRefresh
  );
  const setProcessRefresh = savedSearchesStore(
    (state) => state.setProcessRefresh
  );

  const potentialModalVisible = savedSearchesStore(
    (state) => state.potentialModalVisible
  );
  const setPotentialModalVisible = savedSearchesStore(
    (state) => state.setPotentialModalVisible
  );

  const potentialData = savedSearchesStore((state) => state.potentialData);
  const setPotentialData = savedSearchesStore(
    (state) => state.setPotentialData
  );

  const contactID = savedSearchesStore((state) => state.contactID);
  const setContactID = savedSearchesStore((state) => state.setContactID);

  const [
    potentialMatches,
    { data: potentialDataResult, loading: potentialLoading },
  ] = useLazyQuery(GET_POTENTIAL_MATCHES);

  useEffect(() => {
    if (
      !potentialLoading &&
      potentialDataResult &&
      potentialDataResult.getPotentialMatches?.length
    ) {
      setPotentialData(potentialDataResult.getPotentialMatches);
      setPotentialModalVisible(true);
    }
  }, [potentialLoading, potentialDataResult]);

  useEffect(() => {
    refsList?.current?.scrollTo(0, savedSearchPosition);
  }, []);

  const handleScroll = () => {
    const position = refsList?.current?.scrollTop;
    if (position > 0) {
      setSavedSearchPosition(position);
    }
  };

  const {
    filterList,
    onFilterSelection,
    clearFiler,
    onFilterCountUpdate,
    updateAvailableFilterScoreWise,
  } = LocalFilterList({
    categories: IS_WATCHED_SEARCH ? closestCategories : savedStoreCategories,
  });

  useEffect(() => {
    if (IS_WATCHED_SEARCH && closestCategories?.length > 0) {
      updateAvailableFilterScoreWise(closestCategories);
    } else if (!IS_WATCHED_SEARCH && savedStoreCategories?.length > 0) {
      updateAvailableFilterScoreWise(savedStoreCategories);
    }
  }, [IS_WATCHED_SEARCH]);

  useEffect(() => {
    onFilterCountUpdate(availableFilterList);
  }, [availableFilterList]);

  const onSavePotentialMatch = () => {
    Variables.isAddSearches = true;
    Variables.isRefreshClosestContacts = true;
    setForceRefresh(true);
    setPotentialModalVisible(false);
    getFerretContactList();
  };

  const broadcast = new BroadcastChannel("background-contact-process-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        refreshContacts(res.data);
      };
    }
  }, []);

  const refreshContacts = (payload) => {
    if (payload?.data?.type === "import-contact-process-notification") {
      setProcessRefresh(true);
      clearTimeout(lastTimeout);
      lastTimeout = setTimeout(() => {
        // To avoid multiple API call once received multiple notification
        getFerretContactList();
        if (IS_WATCHED_SEARCH) {
          Variables.isAddSearches = true;
        } else {
          Variables.isRefreshClosestContacts = true;
        }
      }, REFRESH_TIMEOUT);
    }
  };

  const getPotentialMatches = (contactID, index) => {
    setSelectedPotentialConatactIndex(index);
    setContactID(contactID);
    potentialMatches({ variables: { contactId: contactID } });
  };

  const [
    getSavedSearches,
    {
      loading: loadingContactsWatch,
      data: dataContactsWatch,
      error: errorContactsWatch,
    },
  ] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: "no-cache",
  });

  const [
    getContactList,
    { loading: loadingContacts, data: dataContacts, error: errorContacts },
  ] = useLazyQuery(GET_FERRET_CONTACT_LIST, {
    fetchPolicy: "no-cache",
  });

  const debouncedFerretContactList = () => {
    setOldPageName('')
    if (IS_WATCHED_SEARCH) {
      getSavedSearches({
        variables: { showPreferred: true },
      });
    } else {
      getContactList({
        variables: {
          showSavedSearches: true,
        },
      });
    }
  };

  useEffect(() => {
    if (IS_WATCHED_SEARCH && closetSearches?.length == 0 && !dataContactsWatch) {
      getFerretContactList()
    }
  }, [closetSearches])

  const getFerretContactList = _.debounce(debouncedFerretContactList, 1000);

  useEffect(() => {
    if (
      IS_WATCHED_SEARCH
    ) {
      setClosetSearches([])
      setForceRefresh(true);
      getFerretContactList();
    } else if (
      !IS_WATCHED_SEARCH
    ) {
      setForceRefresh(true);
      debouncedFerretContactList()
    }
  }, [IS_WATCHED_SEARCH]);

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      setLoadingCard(false);
      Variables.isAddSearches = false;
      setProcessRefresh(false);
      setForceRefresh(false);
      setSavedStoreSearches(dataContacts?.contactList?.contacts);
      setSavedStoreCategories(dataContacts?.contactList?.categories);
      updateAvailableFilterScoreWise(dataContacts.contactList.categories);
    }
  }, [loadingContacts, dataContacts, errorContacts]);

  useEffect(() => {
    if (!loadingContactsWatch && dataContactsWatch) {
      setLoadingCard(false);
      setProcessRefresh(false);
      setForceRefresh(false);
      Variables.isRefreshClosestContacts = false;
      if (dataContactsWatch && dataContactsWatch?.getSavedSearches?.contacts) {
        setClosetSearches(dataContactsWatch.getSavedSearches.contacts);
        setClosestCategories(dataContactsWatch.getSavedSearches.categories);
        updateAvailableFilterScoreWise(
          dataContactsWatch.getSavedSearches.categories
        );
      }
      if (
        dataContactsWatch &&
        dataContactsWatch?.getSavedSearches?.totalContacts
      ) {
        setTotalImportCount(dataContactsWatch.getSavedSearches.totalContacts);
      }
    }
  }, [loadingContactsWatch, dataContactsWatch, errorContactsWatch]);

  const [
    saveAdhocSearch,
    { data: saveData, loading: saveLoading, error: saveError },
  ] = useMutation(SAVE_ADHOC_SEARCH);

  useEffect(() => {
    if (!saveLoading && (saveData || saveError)) {
      setProcessRefresh(true);
      getFerretContactList();
    }
  }, [saveData, saveLoading, saveError]);

  const [searchString, setSearchString] = useState(
    initialSearchString ? formatUserName(initialSearchString.trim()) : ""
  );
  const [phoneNumberInput, setPhoneNumber] = useState(mPhoneNumberInput);
  const [countryInput, setCountry] = useState(mCountryInput);
  const [cityInput, setCity] = useState(mCityInput);
  const [stateInput, setState] = useState(mStateInput);
  const [ageInput, setAge] = useState(mAgeInput);
  const [searchName, setsearchName] = useState();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [irrelevantArticleId, setIrrelevantArticleId] =
    useState(recentSearchId);
  const [irrelevantArticles, setIrrelevantArticles] = useState();
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [clickedPotential, setClickedPotential] = useState(false);
  const [searchContext, setSearchContext] = useState();
  const [clickedSavedSearch, setClickedSavedSearch] = useState(false);
  const [clickedEditSearch, setClickedEditSearch] = useState(false);
  const [savedContactID, setSavedContactId] = useState(contactID);
  const [savedTahoeId, setSavedTahoeId] = useState(mSavedTahoeId);
  const [agePotentialMatch, setAgePotentialMatch] = useState(age);
  const [savedTracerQueryId, setSavedTracerQueryId] =
    useState(mSavedTracerQueryId);
  const [queryData, setQueryData] = useState(mQueryData);
  const [oldMetaData, setOldMetaData] = useState(intitialMetadata);
  const { user, setUser } = useContext(UserContext);
  const [metaData, setMetaData] = useState(qaMetaData);
  const [metaDataArray, setMetaDataArray] = useState();
  const [sectionIndex, setSectionIndex] = useState(0);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [searchLimitExceedTitle, setSearchLimitExceedTitle] = useState(null);
  const [openPotentialMatch, setOpenPotentialMatch] = useState(false);
  const [mFromBackSearch, setFromBackSearch] = useState(fromBackSearch);
  const [progress, setProgress] = useState(false);

  const [lastQuery, setLastQuery] = useState();
  const [showAddOrgsModal, setShowAddOrgsModal] = useState();
  const [isEditFromOverlay, setEditFromOverlay] = useState();

  const searchFormData = searchFormStore((state) => state.searchFormData);
  const searchResultData = searchFormStore((state) => state.searchResultData);
  const setSearchData = searchFormStore((state) => state.setSearchData);
  const isEditedSavedsearch = searchFormStore(
    (state) => state.isEditedSavedsearch
  );
  const setEditedSavedsearch = searchFormStore(
    (state) => state.setEditedSavedsearch
  );
  const selectedPotentialIndex = searchFormStore(
    (state) => state.selectedPotentialIndex
  );
  const setSelectedPotentialIndex = searchFormStore(
    (state) => state.setSelectedPotentialIndex
  );
  const [selectedPotentialConatactIndex, setSelectedPotentialConatactIndex] =
    useState(-1);
  const savedSearchData = searchFormStore((state) => state.savedSearchData);
  const savedPotentialMatch = searchFormStore(
    (state) => state.savedPotentialMatch
  );
  const setPotentialMatch = searchFormStore((state) => state.setPotentialMatch);
  const setOpenEditSearch = searchFormStore((state) => state.setOpenEditSearch);
  const setEditSearchData = searchFormStore((state) => state.setEditSearchData);
  const editSearchComplete = searchFormStore(
    (state) => state.editSearchComplete
  );
  const setEditSearchComplete = searchFormStore(
    (state) => state.setEditSearchComplete
  );
  const [loadingCard, setLoadingCard] = useState();

  useEffect(() => {
    if (editSearchComplete) {
      // editSearchData?searchSettings
      setEditedSavedsearch(true);
      setIrrelevantArticleId(editSearchComplete?.recentSearchId);
      setQuestions(removeIdontKnowLabel(editSearchComplete?.questions));
      setSearchString(
        editSearchComplete?.searchString
          ? formatUserName(editSearchComplete?.searchString?.trim())
          : ""
      );
      setAge(searchFormData?.age?.toString() ?? "");
      setCity(capitalizeCase(searchFormData?.city ?? ""));
      setCountry(searchFormData?.country ?? "");
      setPhoneNumber(searchFormData?.phone ?? "");
      setState(searchFormData?.state ?? "");

      setSearchContext({
        "age": searchFormData?.age?.toString() ?? "",
        "state": searchFormData?.state ?? "",
        "city": capitalizeCase(searchFormData?.city ?? ""),
        "country": searchFormData?.country ?? "",
        "entityType": "PERSON",
        "phone": searchFormData?.phone ?? "",
        "title": editSearchComplete?.searchString
          ? formatUserName(editSearchComplete?.searchString?.trim())
          : ""
      })

      setMetaDataArray();
      setPotentialMatch([]);
      setSearchData();
      removeAll();
      setEditSearchData({});
      setFromBackSearch(false);
      setEditFromOverlay(true);
      if (loading || loadingData) return;

      setSearchCompleted(true);
      setClickedPotential(false);
      setClickedSavedSearch(false);
      setQueryData();

      startProgressBar();
      setLastQuery(editSearchComplete?.queryParms);
      refineSearch(editSearchComplete?.queryParms);
      setEditSearchComplete();
      setOpenEditSearch(false);
    }
  }, [editSearchComplete]);

  const { loadingData, searchSettings, getModalDataForMetaTag } =
    useIntialModalData({}, "", "", savedPotentialMatch, mFromBackSearch);

  useEffect(() => {
    if (
      (clickedSavedSearch || clickedPotential || clickedEditSearch) &&
      !loadingData &&
      searchSettings &&
      searchSettings?.initialSearchString?.length
    ) {
      goToResults();
      stopProgressBar();

      let searchData = { ...searchSettings };
      let data = {
        inNewSearch: true,
        fromEditSearch: true,
        isRecentSearch: false,
        fromBackSearch: false,
        searchSettings: searchData,
        cleanStateOnBlur: false,
        isAutoFocus: false,
        isEdit: true,
        mPhoneNumberInput: phoneNumberInput,
        mCountryInput: countryInput,
        mCityInput: cityInput,
        mStateInput: stateInput,
        mAgeInput: ageInput,
        mQueryData: queryData,
        mSavedTracerQueryId: savedTracerQueryId,
        mSavedTahoeId: savedTahoeId,
        recentSearchId: irrelevantArticleId,
        qaMetaData: metaData,
      };
      console.log('data=====', data)
      setEditSearchData(data);
    }
  }, [searchSettings]);

  const startProgressBar = () => {
    {
      let prog = 0;
      clearInterval(timer);
      timer = setInterval(() => {
        prog += 1;
        if (prog > 90) {
          // clearInterval(timer)
          return;
        } else {
          setProgress(prog);
        }
      }, 150);
    }
  };

  const stopProgressBar = () => {
    clearInterval(timer);
    setProgress(0);
  };

  useEffect(() => {
    if (!!data && !loading) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        stopProgressBar();
        setOpenPotentialMatch(false);
        setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
        setSearchLimitExceed(true);
        const updateUserInfo = { ...user, searchLimitExceed: true };
        setUser(updateUserInfo);
      } else {
        if (user.itemId === FREE_PLAN_ID && clickedPotential) {
          let currentMonthAdhocSearchCount =
            user.adhocSearchLimitPerMonth -
            data?.refine_search?.currentMonthAdhocSearchCount;
          let divideByThree = parseInt(user.adhocSearchLimitPerMonth / 3);
          if (
            currentMonthAdhocSearchCount == divideByThree ||
            currentMonthAdhocSearchCount < 3
          ) {
            stopProgressBar();
            setSearchLimitExceed(true);
            setOpenPotentialMatch(true);
            switch (true) {
              case currentMonthAdhocSearchCount === divideByThree:
                setSearchLimitExceedTitle(
                  `You Have ${currentMonthAdhocSearchCount} Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 2:
                setSearchLimitExceedTitle(
                  `You Have 2 Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 1:
                setSearchLimitExceedTitle(
                  `You Have 1 Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 0:
                setOpenPotentialMatch(false);
                setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
                const updateUserInfo = { ...user, searchLimitExceed: true };
                setUser(updateUserInfo);
              default:
                break;
            }
          } else {
            openPotentialMatchScreen();
          }
        } else {
          openPotentialMatchScreen();
        }
      }
    }
  }, [data, loading]);

  const openPotentialMatchScreen = () => {
    saveSearches(); // change potential-match-search to complete-search for ticket 4124
  };

  const saveSearches = (mQueryData) => {
    const name = searchName
      ? searchName
      : searchString
        ? searchString
        : mQueryData?.title ?? "";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(questions, metaData);
    if (JSON.stringify(newMetadata) === "{}" || !newMetadata?.Person || newMetadata?.Person === '') {
      newMetadata = { ...newMetadata, ...{ Person: name } };
      setMetaData(JSON.stringify(newMetadata));
    }

    let variables = {
      data: {
        // "contactId": contactItem.id,
        searchTerm: name,
        searchType:
          newMetadata.Person && newMetadata.Person != ""
            ? "PERSON"
            : newMetadata?.Company?.toLowerCase() == name?.toLowerCase()
              ? "COMPANY"
              : newMetadata?.Location?.toLowerCase() == name?.toLowerCase()
                ? "LOCATION"
                : newMetadata?.Place?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : newMetadata?.Nationality?.toLowerCase() == name?.toLowerCase()
                    ? "PLACE"
                    : "PERSON",
        eventType: "potential-match-search",
        metaData: {
          Person: [newMetadata.Person ? newMetadata.Person : ""],
          Company:
            clickedSavedSearch && metaDataArray?.Company
              ? metaDataArray.Company
              : [newMetadata.Company ? newMetadata.Company : ""],
          Location: [newMetadata.Location ? newMetadata.Location : ""],
          Place:
            clickedSavedSearch && metaDataArray?.Place
              ? metaDataArray.Place
              : [newMetadata.Place ? newMetadata.Place : ""],
          Nationality: [newMetadata.Nationality ? newMetadata.Nationality : ""],
        },
        searchContext: {},
      },
    };

    const akaList = getAKAList();
    if (akaList && akaList.length) {
      variables.data.akaList = akaList;
    }

    if (searchContext) {
      if (searchContext.age) {
        setAge(searchContext.age)
        variables.data.searchContext.age = searchContext.age;
      }
      if (searchContext.city) {
        setCity(searchContext.city)
        variables.data.searchContext.city = searchContext.city;
      }
      if (searchContext.country) {
        setCountry(searchContext.country)
        variables.data.searchContext.country = searchContext.country;
      }
      if (searchContext.entityType) {
        variables.data.searchContext.entityType = searchContext.entityType;
      }
      if (searchContext.phone) {
        setPhoneNumber(searchContext.phone)
        variables.data.searchContext.phone = searchContext.phone;
      }
      if (searchContext.state) {
        setState(searchContext.state)
        variables.data.searchContext.state = searchContext.state;
      }
      if (searchContext.title) {
        variables.data.searchContext.title = searchContext.title;
      }

      setQueryData(variables.data.searchContext);
    }

    if (savedTahoeId) {
      variables.data.tahoeId = savedTahoeId;
    }
    if (savedTracerQueryId) {
      variables.data.tracerQueryId = savedTracerQueryId;
    }

    if (agePotentialMatch) {
      variables.data.age = agePotentialMatch;
    }

    if (irrelevantArticleId) variables.data.id = irrelevantArticleId;

    if (Variables.potentialMatchId)
      variables.data.uuid = getPotentialMatchId();

    save_Article({
      variables: variables,
    });
  };

  const cancelPopup = () => {
    setOpenSubscription(false)
    navigateToDashboard()
  };

  const startFreeTrial = async () => {
    setOpenSubscription(true);
    setSearchLimitExceed(false);
  };

  const navigateToDashboard = () => {
    setSearchLimitExceed(false);
    if (!openPotentialMatch) {
      // navigate(-1, { replace: true });
    } else {
      setOpenPotentialMatch(false);
      startProgressBar();
      openPotentialMatchScreen();
    }
  };

  const onGetQABotOption = () => {
    const name = searchName ? searchName : searchString;
    const searchType =
      metaData?.Person && metaData?.Person != ""
        ? "PERSON"
        : metaData?.Company?.toLowerCase() == name?.toLowerCase()
          ? "COMPANY"
          : metaData?.Location?.toLowerCase() == name?.toLowerCase()
            ? "LOCATION"
            : metaData?.Place?.toLowerCase() == name?.toLowerCase()
              ? "PLACE"
              : metaData?.Nationality?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : "PERSON";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(questions, metaData);
    getModalDataForMetaTag(
      searchType,
      newMetadata,
      name,
      phoneNumberInput,
      countryInput,
      cityInput,
      stateInput,
      ageInput
    );
  };

  const [save_Article, { loading: loadingSaveSeaches }] = useMutation(
    Save_Recent_Search,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        setIrrelevantArticleId(data.saveRecentSearch.id);
        setIrrelevantArticles(data.saveRecentSearch.irrelevantArticles);
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
      onError(error) {
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
    }
  );

  const onOpenPotentialMatch = (item, index) => {
    // if (isEditedSavedsearch) {
    //   setShowSaveConfirmationAlert({
    //     status: true,
    //     msg: saveSearchVariables?.data?.searchContext?.title ?? saveSearchVariables?.data?.name,
    //     onPotentialSelected: () => {
    //       onContactSelected(item, index);
    //     },
    //   });
    // } else {
    if (progress == 0)
      onContactSelected(item, index);
    // }
  };

  const onContactSelected = (item, index) => {
    if (progress > 0 && savedPotentialMatch.length > 0) return;
    setFromMultipleSavedSearch(false);
    setEditedSavedsearch(false);
    setAgePotentialMatch(item?.contactItem?.age);
    setSavedTahoeId(item.tahoeId);
    setSavedTracerQueryId(item.tracerQueryId);
    setSavedContactId(item?.contactItem?.id);
    setContactID(item?.contactItem?.id);
    setsearchName(item.searchTerm);
    setMetaData(item.metadata);
    setSearchCompleted(true);
    setClickedPotential(true);
    setIrrelevantArticleId(item.recentSearchId);
    setSearchContext(item.searchContext);
    setQueryData();
    let variables = {
      fullname: {
        search: "search",
        meta_data: JSON.stringify(item?.requestMetadata),
        contactId: item?.contactItem?.id,
        source: "ADHOC_SEARCH",
      },
    };
    if (item?.tahoeId) variables.fullname.tahoeId = item.tahoeId;
    if (item?.tracerQueryId)
      variables.fullname.tracerQueryId = item.tracerQueryId;

    let query = {
      variables,
    };

    setShowAddOrgsModal(
      checkCompanyInMetaData(query, setShowAddOrgsModal, (query, company) => {
        setSelectedItem(item?.contactItem);
        setPreferred(item?.contactItem?.isPreferred);
        setSelectedPotentialIndex(index);
        setSearchData();
        setEditFromOverlay(false);
        removeAll();
        startProgressBar();
        setLastQuery(query);
        refineSearch(query);

        if (company && company?.length > 0) {
          setMetaData({ ...metaData, Company: company?.join(";")?.toString() });
        }
      })
    );
  };

  const goToResults = (id, articles, contactId) => {
    const articleDetails = getArticleDetailsFromRefineSearch();
    if (!articleDetails) {
      setSearchCompleted(false);
      return;
    }
    Variables.potentialMatchId = getPotentialMatchId();
    Variables.contactId = getContactId();
    const readArticles = getReadArticlesIds();
    const articleFilterData = getArticleFilterData();
    const aKAList = getAKAList();
    const personalInfo = getPersonalInfo();
    const articleTypeCount = getArticleTypeCount();
    const parsedArticles = articleDetails
      .filter((item) => {
        return item;
      })
      .map((item) =>
        mapSingleArticle(item, searchName ?? searchString, readArticles)
      ); // filter null article
    const metaTags = clickedPotential
      ? metaData
      : getMetadataFromQuestions(questions, metaData);
    const paramsTag =
      clickedSavedSearch || clickedPotential
        ? getSavedSearchParams(metaData)
        : searchParams;
    const name = searchName ? searchName : searchString;

    const data = {
      inNewSearch: inNewSearch,
      lastQuery: lastQuery,
      irrelevantArticleId: id ? id : irrelevantArticleId,
      irrelevantArticles: articles ? articles : irrelevantArticles,
      metadata: metaTags,
      searchTerm: searchName,
      searchType:
        metaTags?.Person && metaTags?.Person != ""
          ? "PERSON"
          : metaTags?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : metaTags?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : metaTags?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : metaTags?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON",
      articles: parsedArticles,
      savedContactID: contactId ?? savedContactID,
      savedTahoeId: savedTahoeId,
      savedTracerQueryId: savedTracerQueryId,
      articleFilterData: articleFilterData,
      personalInfo: personalInfo,
      articleTypeCount: articleTypeCount,
      clickedPotential: clickedPotential,
      potentialsearch: searchString,
      aKAList: aKAList,
      searchSettings:
        clickedSavedSearch || clickedPotential || clickedEditSearch
          ? { ...searchSettings, isAdhocSearch: true }
          : {
            initialSearchString: name,
            intitialMetadata: metaTags,
            initialSearchParams: paramsTag,
            initialQuestions: questions,
            qaMetaData: metaData,
            initialPotentialMatch: savedPotentialMatch,
            isAdhocSearch: true,
          },
      mPhoneNumberInput: phoneNumberInput,
      mCountryInput: countryInput,
      mCityInput: cityInput,
      mStateInput: stateInput,
      mAgeInput: ageInput,
      queryData: queryData,
      potentialMatchId: getPotentialMatchId(),
      mOldMetaData: oldMetaData,
      navigatedFrom: navigatedFrom,
      categoryFilterData: getCategoryFilterData(),
      articleSource: clickedSavedSearch ? "SEARCH" : "SEARCH",
      navigatedFrom: !inNewSearch && isEdit ? "SavedContactsArticles" : "",
      searchIds: getSearchId(),
      linkedInData: getLinkedInData(),
      feedbackOptions: getFeedbackOptions(),
      profilePic: getProfilePic(),
      isFromSavedSearch: true,
      fromMultipleSavedSearch: fromMultipleSavedSearch,
      isEditedSavedsearch: isEditedSavedsearch,
    };
    setSearchData(data);

    if (selectedItem?.availableFilter?.length == undefined || selectedItem?.availableFilter?.length == 0) {
      setProcessRefresh(true);
      getFerretContactList();
      if (IS_WATCHED_SEARCH) {
        Variables.isAddSearches = true;
      } else {
        Variables.isRefreshClosestContacts = true;
      }
    }

  };

  useEffect(() => {
    if (selectedPotentialMatchData) {
      let potentialMatchData = { ...selectedPotentialMatchData };
      removeAllSearchForm();
      setFromMultipleSavedSearch(true);
      setPotentialModalVisible(false);
      onPotentialSelected(potentialMatchData, selectedPotentialConatactIndex);
    }
  }, [selectedPotentialMatchData]);

  const onPotentialSelected = (item, index) => {
    if (progress > 0 && savedPotentialMatch.length > 0) return;
    setSavedTahoeId(item.TahoeId);
    setSavedTracerQueryId(item.TracerQueryId);
    setsearchName(item.Person);

    let metadata = {};
    if (item?.Company && item?.Company?.length) {
      item?.Company?.forEach((item) => {
        if (item)
          metadata.Company = metadata.Company
            ? metadata.Company + ";" + item
            : item;
      });
    }
    if (item?.Person) metadata.Person = item.Person;

    if (item?.Place && item?.Place?.length) {
      item?.Place?.forEach((item) => {
        let place;
        if (item) {
          if (
            item?.city &&
            queryData?.city?.toLowerCase() != item?.city?.toLowerCase()
          )
            place = item.city;
          if (
            item?.state &&
            queryData?.state?.toLowerCase() != item?.state?.toLowerCase()
          )
            place = place ? place + ", " + item.state : item.state;
          if (
            item?.country &&
            queryData?.country?.toLowerCase() != item?.country?.toLowerCase()
          )
            place = place ? place + ", " + item.country : item.country;
        }
        if (place)
          metadata.Place = metadata.Place
            ? metadata.Place + ";" + place
            : place;
      });
    }

    if (queryData?.city) queryData.city = queryData.city.toLowerCase();

    setMetaData(metadata);
    setSearchCompleted(true);
    setClickedPotential(true);
    setQueryData();

    let metadataQuery = addQueryData(queryData, { ...metadata });

    let variables = {
      fullname: {
        search: "search",
        meta_data: JSON.stringify(metadataQuery),
        source: "ADHOC_SEARCH",
      },
    };

    if (item?.TahoeId) variables.fullname.tahoeId = item.TahoeId;
    if (item?.TracerQueryId)
      variables.fullname.tracerQueryId = item.TracerQueryId;

    let query = {
      variables,
    };
    setShowAddOrgsModal(
      checkCompanyInMetaData(query, setShowAddOrgsModal, (query, company) => {
        setSelectedPotentialIndex(index);
        setSearchData();
        setEditFromOverlay(false);
        removeAll();
        startProgressBar();
        setLastQuery(query);
        refineSearch(query);

        if (company && company?.length > 0) {
          setMetaData({ ...metadata, Company: company?.join(";")?.toString() });
        }
      })
    );
  };

  useEffect(() => {
    if (location.pathname != selectedPage) {
      setSelectedPage(location.pathname);
      onReset();
    } else {
      setSearchValue(searchValue);
    }
  }, [IS_WATCHED_SEARCH]);

  const onReset = () => {
    try {
      refAlpha?.current?.setSelectedAlphabetScroll("");
    } catch (e) { }
    try {
      refsViewOnlyList?.current?.onAplhaFilterSelected("reset");
    } catch (e) { }
    setSearchValue("");
    setSavedSearchPosition(0);
    setGoogleCheck(true);
    setPhoneCheck(true);
    setSavedCheck(true);
    setICloudCheck(true);
    setSearchValue("");
    clearFiler();
    onFilterTypeClick(
      filterList.map((item, index) => {
        return { ...item, status: false };
      })
    );
    removeAll();
    removeAllSearchForm();
    setFilterReset(!filterReset);
  };

  const saveMySearches = () => {
    backToSearchComponent();
    if (saveSearchVariables == undefined) return;
    saveSearchVariables.data.searchIds = getSearchId();
    saveSearchVariables.data.notifyUser = chkBxNotifyUser;
    saveSearchVariables.data.monitorSearch = monitorSearch;
    saveSearchVariables.data.saveSearch = monitorSearch ? true : saveSearch;
    setLoadingCard(selectedPotentialIndex);
    saveAdhocSearch({
      variables: saveSearchVariables,
    });
    if (monitorSearch) Variables.isRefreshClosestContacts = true;
    Variables.isAddSearches = true;
  };

  const backToSearchComponent = () => {
    setShowSaveConfirmationAlert({ status: false, msg: "" });
    showSaveConfirmationAlert?.onPotentialSelected?.();
  };

  const onEdit = () => {
    setOpenEditSearch(true);
  };

  const onFilterTypeClick = (filterList) => {
    setSelectedFilter(
      filterList
        ?.filter((item) => item.count && item.status)
        .map((item) => item.category)
        ?.toString()
    );
  };
  const onFilterClick = (category, status) => {
    onFilterSelection(category, status);
    onFilterTypeClick(
      filterList.map((item, index) => {
        return {
          ...item,
          status: item.category == category ? status : item.status,
        };
      })
    );
  };

  const onPressImport = () => {
    Variables.navigationStackNameForContacts = "SavedSearches";
    const data = {
      screenName: "SavedSearches",
    };
    navigate("/importContactsLanding", { state: JSON.stringify(data) });
  };
  const onPressStar = () => {
    navigate("/savedSearch");
  };

  let alphaArray = [];
  Object.keys(contacts).map((key) => {
    alphaArray = [...alphaArray, key];
  });

  const goBack = () => {
    setShowSaveConfirmationAlert({ status: false, msg: "" });
  };
  const normalise = (value) => ((value - 0) * 100) / (100 - 0);
  /**
   * Main render method
   */
  return (
    <div className={Styles.savedMain}>
      {(!IS_WATCHED_SEARCH &&
        savedStoreSearches &&
        savedStoreSearches.length > 0) ||
        (IS_WATCHED_SEARCH && closetSearches && closetSearches.length > 0) ? (
        <>
          <aside>
            <div className={Styles.bodyLeft}>
              <span className={Styles.headerText}>Sort and Filter</span>
              <div className={Styles.searchView}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={searchValue}
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  placeholder={"Search Contacts"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchFiter />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ButtonBase
                          style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 4,
                          }}
                          onClick={handleCancel}
                        >
                          <img src={CloseBt}></img>
                        </ButtonBase>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <AlphabetScroll
                ref={refAlpha}
                onClick={(a) => {
                  refsViewOnlyList?.current?.onAplhaFilterSelected(a);
                }}
                styles={Styles.marginTop}
                alphaArray={alphaArray}
              />
              <span className={Styles.headerTitle}>Filter By Record Type</span>
              {filterList &&
                filterList?.map((item, index) => (
                  <CheckFilterRecent
                    index={index}
                    label={item.category}
                    status={item.status}
                    count={item.count}
                    setStatus={() => onFilterClick(item.category, !item.status)}
                  />
                ))}
              <span className={Styles.headerTitle}>Imported Source</span>
              <div className={Styles.checkView}>
                <div>
                  <Checkbox
                    disabled={false}
                    checked={googleCheck}
                    onClick={() => setGoogleCheck(!googleCheck)}
                    color="success"
                    sx={{
                      color: "#078167",
                      "&.Mui-checked": {
                        color: "#078167",
                      },
                      paddingLeft: 0,
                    }}
                  />
                  <span className={Styles.checkTitle}>Google</span>
                </div>
                <div>
                  <Checkbox
                    disabled={false}
                    checked={phoneCheck}
                    onClick={() => setPhoneCheck(!phoneCheck)}
                    color="success"
                    sx={{
                      color: "#078167",
                      "&.Mui-checked": {
                        color: "#078167",
                      },
                      paddingLeft: 0,
                    }}
                  />
                  <span className={Styles.checkTitle}>Personal Contacts</span>
                </div>
                <div>
                  <Checkbox
                    disabled={false}
                    checked={savedCheck}
                    onClick={() => setSavedCheck(!savedCheck)}
                    color="success"
                    sx={{
                      color: "#078167",
                      "&.Mui-checked": {
                        color: "#078167",
                      },
                      paddingLeft: 0,
                    }}
                  />
                  <span className={Styles.checkTitle}>Saved on Ferret</span>
                </div>
                <div>
                  <Checkbox
                    disabled={false}
                    checked={iCloudCheck}
                    onClick={() => setICloudCheck(!iCloudCheck)}
                    color="success"
                    sx={{
                      color: "#078167",
                      "&.Mui-checked": {
                        color: "#078167",
                      },
                      paddingLeft: 0,
                    }}
                  />
                  <span className={Styles.checkTitle}>iCloud</span>
                </div>
              </div>
              <div className={Styles.bottomView}>
                <button
                  className="btn btn--icon"
                  style={{ padding: 10, width: "100%" }}
                  onClick={onReset}
                >
                  <img src={ResetIcon} alt="Reset" />
                  Reset
                </button>
              </div>
            </div>
          </aside>
          <div className={Styles.container}>
            <div className={Styles.containerCenter}>
              {(loadingContacts || potentialLoading) &&
                (!isProcessRefresh || forceRefresh) && (
                  <div className={Styles.containerRightparantProgressbar}>
                    <LoadingBar />
                  </div>
                )}
              <PotentialMatch
                contactID={contactID}
                potentialModalVisible={potentialModalVisible}
                setPotentialModalVisible={setPotentialModalVisible}
                potentialData={potentialData}
                onSavePotentialMatch={onSavePotentialMatch}
                isWeb={true}
              />
              <SavedSearchesList
                getPotentialMatches={getPotentialMatches}
                categories={
                  IS_WATCHED_SEARCH ? closestCategories : savedStoreCategories
                }
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                selectedProviders={providers}
                onContactSelected={onOpenPotentialMatch}
                refsList={refsList}
                refsListLI={refsListLI}
                refs={refs}
                refsViewOnlyList={refsViewOnlyList}
                selectedPotentialIndex={selectedPotentialIndex}
                IS_WATCHED_SEARCH={IS_WATCHED_SEARCH}
                savedSearches={
                  IS_WATCHED_SEARCH ? closetSearches : savedStoreSearches
                }
                setSavedSearches={
                  IS_WATCHED_SEARCH ? setClosetSearches : setSavedStoreSearches
                }
                loadingCard={loadingCard}
                handleScroll={handleScroll}
                filterReset={filterReset}
              />
            </div>
            {progress > 0 && (
              <div className={Styles.recentSearchView}>
                <div style={{ height: 70 }}></div>
                <div className={Styles.containerRightparantRecent}>
                  <div className={Styles.progressView}>
                    <ColoredLinearProgress
                      variant="determinate"
                      value={normalise(progress)}
                      style={{
                        height: 14,
                        width: "100%",
                        borderRadius: 7,
                        alignSelf: "center",
                        marginLeft: progress > 0 ? 10 : 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {searchResultData && (
              <div className={Styles.recentSearchView}>
                <div className={Styles.header}>
                  <span className={Styles.recentSerchTxt}>{""}</span>
                  {!fromMultipleSavedSearch ? (
                    <div className="flex flex-row">
                      <WhiteBgButtonGreenBorder
                        onClick={() => {
                          setOpenMapDashboard(true)
                        }}
                        style={{ width: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
                      >
                        <span className={Styles.generatePdfText}>Open Map</span>
                      </WhiteBgButtonGreenBorder>

                      <WhiteBgButtonGreenBorder
                        loading={isPdfGenerating}
                        onClick={() => {
                          setIsPdfGenerating(true)
                          generatePdf(
                            'content-to-pdf',
                            titleCase(searchName ?? searchString),
                            () => {
                              setIsPdfGenerating(false)
                            })
                        }}
                        style={{ width: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
                      >
                        <span className={Styles.generatePdfText}>Generate PDF</span>
                      </WhiteBgButtonGreenBorder>
                      <button
                        onClick={onEdit}
                        className="w-1/2 btn btn--primary"
                        style={{ maxWidth: 150, padding: 10 }}
                      >
                        Edit Search
                      </button>
                    </div>

                  ) : (
                    <div style={{ height: 50 }}></div>
                  )}
                </div>
                <div className={Styles.containerRightparantRecent}>
                  <SearchResultsWithQuery
                    searchResultData={searchResultData}
                  />
                </div>
              </div>
            )}
            {showAddOrgsModal && showAddOrgsModal}

            {isSearchLimitExceed && (
              <SearchLimitExceedModal
                title={searchLimitExceedTitle}
                onBackdropPress={() => {
                  setSearchLimitExceed(false);
                }}
                  startFreeTrial={startFreeTrial}
                  goback={cancelPopup}
                modelType={ModelType.SEARCH_LIMIT_MODAL}
              />
              )}
          </div>
        </>
      ) : (
        <>
          {(loadingContacts || loadingContactsWatch) && !isProcessRefresh ? (
            <div className={Styles.containerRightparantProgressbar}>
              <LoadingBar />
            </div>
          ) : !loadingContacts && !IS_WATCHED_SEARCH && savedStoreSearches?.length == 0 ? (
            <ContactImportAlert
              IS_WATCHED_SEARCH={IS_WATCHED_SEARCH}
              onPress={onPressImport}
              style={{ marginTop: 80 }}
            />
          ) : (
            !loadingContacts &&
            IS_WATCHED_SEARCH &&
            dataContactsWatch &&
            closetSearches?.length == 0 && (
              <ContactImportAlert
                IS_WATCHED_SEARCH={IS_WATCHED_SEARCH}
                startedContact={totalImportCount}
                onPress={totalImportCount ? onPressStar : onPressImport}
              />
            )
          )}
        </>
      )}
      {showSaveConfirmationAlert.status && (
        <SaveSearchResultModal
          setShowSaveConfirmationAlert={setShowSaveConfirmationAlert}
          name={titleCase(showSaveConfirmationAlert.msg)}
          cancelBtnClick={goBack}
          setChkBxNotifyUser={setChkBxNotifyUser}
          chkBxNotifyUser={chkBxNotifyUser}
          setSaveSearch={setSaveSearch}
          saveSearch={saveSearch}
          setMonitorSearch={setMonitorSearch}
          monitorSearch={monitorSearch}
          saveMySearches={saveMySearches}
          showNotifyUser={hasData}
        />
      )}

      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}

    </div>
  );
};

// Component export
export default SavedSearch;
