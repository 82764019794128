import React, { useState, useEffect,useRef } from 'react'
import LocalFilterList from '../../utils/getCategoryFilterList'
import { getFilterImage } from '../../images/filterColorIcon/getFilterImage'
import Styles from './filterHeader.module.css'
import {savedSearchesStore} from '../../store/savedSearchesStore'

const FilterHeader = (props) =>{
  const { availableFilter, onFilterTypeClick, categories } = props;
  const scrollRef = useRef();

  const filterPossion = savedSearchesStore((state) => state.filterPossion)
  const setFilterPossion = savedSearchesStore((state) => state.setFilterPossion)

  const {
    filterList,
    onFilterSelection,
    onFilterCountUpdate,
  } = LocalFilterList({ categories });

  useEffect(()=>{
    onFilterCountUpdate(availableFilter)
  },[availableFilter])

  const onFilterClick = (category,status) => {
    onFilterSelection(category, status)
    onFilterTypeClick(filterList.map((item,index)=> {
      return{...item, status: item.category == category ? status : item.status}
    }))
  }

  useEffect(() => {
      scrollRef?.current?.scrollTo({ left: filterPossion});
  }, [])

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const handleScroll = () => {
      const position = scrollRef?.current?.scrollLeft;
      if(position > 0){
        setFilterPossion(position)
      }
  };

  return (
    <div className={Styles.main} ref={props?.ref}>
      <span className={Styles.filterHeaderText}>filter by record types</span>
      <div 
        ref={scrollRef}
        className={Styles.filterViewMain}>
        {
          filterList.map((item,index)=>{
            return (
              <div key={item.lable}>
              {
                index % 2 == 0 && <div>
                    <div className={`${Styles.filterView} ${(filterList[index].count == 0 && !filterList[index].status) && Styles.filterViewDisable}`}
                      style={filterList[index].status ? { backgroundColor: "#E6F2F0" } : {}}
                    onClick={()=>(filterList[index].count > 0 || filterList[index].status) ? onFilterClick(filterList[index]?.category, !filterList[index]?.status) : null}>
                      <div>{getFilterImage(filterList[index].category, true, null, { transform: "scale(.75)", marginTop: 5 })}</div>
                      <span className={Styles.filterText}>
                        {filterList[index].lable === "News" ? (
                          <>
                            {filterList[index].lable} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
                          </>
                        ) : (
                          filterList[index].lable
                        )}
                      </span>
                  </div>
                  {filterList.length > index+1 &&
                      <div className={`${Styles.filterView2} ${(filterList[index + 1].count == 0 && !filterList[index + 1].status) && Styles.filterViewDisable}`}
                        style={filterList[index + 1].status ? { backgroundColor: "#E6F2F0" } : {}}
                    onClick={()=>(filterList[index+1].count > 0 || filterList[index+1].status) ? onFilterClick(filterList[index+1]?.category, !filterList[index+1]?.status) : null}>
                        <div>{getFilterImage(filterList[index + 1].category, true, null, { transform: "scale(.75)", marginTop: 5 })}</div>
                        <span className={Styles.filterText}>
                          {filterList[index + 1].lable === "News" ? (
                            <>
                              {filterList[index + 1].lable} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
                            </>
                          ) : (
                            filterList[index + 1].lable
                          )}
                        </span>
                  </div>}
                </div>
              }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default FilterHeader