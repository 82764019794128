import React, { useState, useEffect } from "react";
import createStyle from "./styles";
import moment from "moment-timezone";
import getNotificationDetails from "./getNotificationDetails";
import SaveArticleRelevancy from "../../../components/articleList/saveArticleRelevancy";
import { useNavigate } from "react-router-dom";
import { GET_RECENT_SEARCH_BY_ID } from "../../../queries/queries";
import { useLazyQuery } from "react-apollo";
import getMetaFromNotification from "../../../utils/getMetaFromNotification";
import { savedSearchArticlesStore } from "../../../store/savedSearchArticlesStore";
import ProfilePicture from "../profilePicture/profilePicture";
import { notificationStore } from "../../../store/notificationStore";
import { searchFormStore } from "../../../store/searchFormStore";
import Markdown from "react-markdown";
import Styles from './notification.module.css'

const NotificationList = ({
  isTransparent,
  item,
  setLoadingCard,
  isFromDashboard = false,
  isShowBorder = true,
  callNotification
}) => {
  const navigate = useNavigate();
  const style = createStyle();
  const date = moment(parseInt(item.createdAt)).fromNow();
  const removeAll = savedSearchArticlesStore((state) => state.removeAll);
  const setSavedSearchData = searchFormStore(
    (state) => state.setSavedSearchData
  );

  const [selectedItem, setSelectedItem] = useState();

  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );

  const { relevantArticle } = SaveArticleRelevancy();

  const {
    getArticleDetails,
    callReadNotificationApi,
    readNotificationData,
    readNotificationError
  } = getNotificationDetails();

  const [
    getRecentSearchById,
    {
      loading: recentSearchLoading,
      data: recentSearchData,
      error: recentSearchError,
    },
  ] = useLazyQuery(GET_RECENT_SEARCH_BY_ID, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!recentSearchLoading && recentSearchData) {
      removeAll?.();
      const itemData = recentSearchData?.getRecentSearchById;
      const {
        metadata,
        searchTerm,
        searchType,
        searchContext,
        tahoeId,
        tracerQueryId,
        requestMetadata,
      } = getMetaFromNotification(itemData);
      let arrayData = [];
      Object.values(metadata)?.forEach((item) => {
        arrayData = [...arrayData, ...item?.split(";")];
      });

      if (searchContext?.age) {
        let age = `App. ${searchContext?.age} y/o`;
        arrayData.splice(1, 0, age + "");
      }
      if (searchContext?.state) arrayData.splice(1, 0, searchContext?.state);
      if (searchContext?.city) arrayData.splice(1, 0, searchContext?.city);
      if (searchContext?.country)
        arrayData.splice(1, 0, searchContext?.country);
      if (searchContext?.phone) arrayData.splice(1, 0, searchContext?.phone);
      if (searchContext?.stateAbbr)
        arrayData.splice(1, 0, searchContext?.stateAbbr);
      if (searchContext?.countryAbbr)
        arrayData.splice(1, 0, searchContext?.countryAbbr);

      // parseSaveRecentSearch(searchTerm, metadata, 'adhoc-search')
      const data = {
        contactItem: {
          ...itemData,
          recentSearchId: itemData?.id,
          id: itemData?.contact,
        },
        metadata,
        searchTerm,
        searchType,
        searchContext,
        tahoeId,
        tracerQueryId,
        requestMetadata,
        feed: {
          fullName: itemData.searchTerm,
          metadata: [],
          searchSettings: { initialSearchParams: arrayData },
        },
      };
      navigate("/search");
      setSavedSearchData({
        searchTerm,
        resp: itemData?.metaData,
        metadata,
        id: itemData?.id,
        irrelevantArticles: [],
        contact: itemData?.contact,
        searchType,
        tracerQueryId,
        tahoeId,
        searchContext,
        searchCompleted: true,
        akaList: [],
        eventType: "complete-search",
        age: "",
        openMetaSearch: true
      });
    }
  }, [recentSearchLoading, recentSearchData]);

  useEffect(() => {
    setLoadingCard?.(recentSearchLoading);
  }, [recentSearchLoading]);

  useEffect(() => {
    if (readNotificationData && !readNotificationError) {
      callNotification()
    }
  }, [readNotificationData, readNotificationError])

  const onCardClick = () => {
    if (item?.type == "Metasearch") {
      callReadNotificationApi(!item.isRead ? item.id : false);
      getRecentSearchById({
        variables: {
          recentSearchId: item.recentSearchId,
        },
      });
      return;
    }
    if (item?.type == "Updates") {
      callReadNotificationApi(!item.isRead ? item.id : false);
      return
    }
    setOpenNotification(true);
    setSelectedItem?.(item.id);
    item?.osArticleId &&
      getArticleDetails(
        {
          variables: {
            articleId: item?.osArticleId,
            contactId: item?.contactId,
          },
        },
        !item.isRead ? item.id : false,
        isFromDashboard,
        relevantArticle,
        item?.contactId
      );
  };

  return (
    <div
      style={
        isTransparent
          ? {
              ...style.viewBanner,
              backgroundColor:
                selectedItem == item.id ? "#E6F2F0" : "transparent",
              borderBottomWidth: isShowBorder ? 1 : 0,
            }
          : {
              ...style.viewBanner,
              backgroundColor:
                selectedItem == item.id ? "#E6F2F0" : "transparent",
              borderBottomWidth: isShowBorder ? 1 : 0,
            }
      }
      onClick={onCardClick}
    >
      {item && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ProfilePicture
            avatarStyle={{
              width: "60px",
              height: "60px",
              backgroundColor: "#C0C7D6",
              borderRadius: 30,
              borderColor: "#FB8100",
              borderWidth: 2,
              borderStyle: "solid",
              isDarkPic: true,
            }}
            profilePhoto={item.contactImage}
          />
          <div style={style.textView}>
            <span style={style.title}>
              {(item.contactName ?? item.title) + " "}
            </span>
            <Markdown
              className={`${Styles.itemBody}`}
              children={item.body}
            />
          </div>
        </div>
      )}
      {item && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 75,
            marginTop: 6,
          }}
        >
          {item?.category?.length > 0 && (
            <span style={style.time}>{item.category}</span>
          )}
          {item?.category?.length > 0 && <span style={style.time}>{"."}</span>}
          <span style={style.time}>{date}</span>
        </div>
      )}
    </div>
  );
};

export default NotificationList;
