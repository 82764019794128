import Iso3166 from "iso-3166-2";
var _ = require("lodash");

const dateDifference = (date2, date1) => {
  const _MS_PER_DAY = 1000 * 60 * 60;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());


  let sss = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return sss;
}
export default dateDifference;

export const objectType = (value) => {
  if (typeof value != 'object') {
    return typeof value
  }
  return Array.isArray(value) ? 'array' : 'object'
}

export function titleCaseWord(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function titleCase(str) {
  var splitStr = str.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export function capitalizeCase(str, isSameCapital) {
  try {
    if (str != null && str != undefined) {
      if (str?.toLowerCase() == "I don't know".toLowerCase()) {
        return str;
      } else {
        var splitStr = str.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          if (isSameCapital && splitStr[i] && splitStr[i]?.split('')?.every(char => char?.toLowerCase() == splitStr[i]?.[0]?.toLowerCase())) {
            splitStr[i] = splitStr[i].toUpperCase()
          } else if (splitStr[i] == splitStr[i].toLowerCase()) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
        }
        // Directly return the joined string
        return splitStr.join(' ');
      }
    }
  } catch (e) {
    return str
  }
}

export function capitalizeQuestionSameCase(str) {
  try {
    if (str != null && str != undefined) {
      var splitStr = str.split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        if (splitStr[i] && splitStr[i]?.split('')?.every(char => char?.toLowerCase() == splitStr[i]?.[0]?.toLowerCase())) {
          splitStr[i] = splitStr[i].toUpperCase()
        }
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }
  } catch (e) {
    return str
  }
}

export function capitalizeSearchTermsTag(str, index, isSameCapital = false) {
  if (str != null && str != undefined) {
    var splitStr = str.split(',');
    if (index == 0) {
      splitStr = splitStr.map((item) => capitalizeCase(item, true))
    } else {
      splitStr = splitStr.map((item) => {
        let _item = item.trim()
        if (_item.length <= 2 || _item.toLowerCase() == "usa" || _item.toLowerCase() == "uae") {
          return _item.toUpperCase()
        } else {
          return capitalizeCase(_item, isSameCapital)
        }
      })
    }
    return splitStr.join(',');
  }
}

function checkArticlehasRelevantInfo(relevanceInfo, isRelevant, isRecent = false) {
  if (relevanceInfo != null && !isRecent) return false
  else if (relevanceInfo?.companyKnow != null) return false
  else if (relevanceInfo?.placesKnow != null) return false
  else if (relevanceInfo?.companyOthers != null) return false
  else if (relevanceInfo?.placesOthers != null) return false
  else if (relevanceInfo?.relevanceAttributes?.length < 1) return false
  else if (isRelevant && isRelevant != "" && isRelevant === "YES") return false
  else return true
};


function checkPARBConfig(itemType, configData) {
  const { getArbConfiguration } = configData;
  let config = getArbConfiguration?.find(item => item.itemType?.toLowerCase() == itemType?.toLowerCase())
  return config ? config.parb : true
}

export function checkParbFunctionVal(configData, configLoading, newsItem, isRecent) {
  let PARBConfigResult;
  if (configData && !configLoading) PARBConfigResult = checkPARBConfig(newsItem?.itemtype, configData);
  let displayPostArticleRelevanceBot = true;

  if (PARBConfigResult) {
    if (newsItem?.relevanceInfo)
      displayPostArticleRelevanceBot = checkArticlehasRelevantInfo(newsItem?.relevanceInfo, newsItem?.isRelevant, isRecent);
  }
  else displayPostArticleRelevanceBot = false;

  if (newsItem?.displayPostArticleRelevanceBot) {
    displayPostArticleRelevanceBot = false
  }
  return displayPostArticleRelevanceBot
}

export function filterAttributeResult(newsItem) {
  const attributesOriginal = []
  const attributesVal = []
  for (let i = 0; i < newsItem.attributes?.length; i++) {
    var arrVal = {
      "groupName": newsItem.attributes[i].key,
      "attributes": []
    }
    var mArrVal = {
      "groupName": newsItem.attributes[i].key,
      "attributes": []
    }
    for (let j = 0; j < newsItem.attributes[i].value?.length; j++) {
      arrVal.attributes.push({
        "label": newsItem.attributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
      mArrVal.attributes.push({
        "label": newsItem.attributes[i].value[j],
        "status": false,
        "type": 'radio_button'
      })
    }
    if (mArrVal?.attributes?.length)
      attributesOriginal.push(mArrVal)
    // arrVal.attributes.push({
    //   "label": 'I don\'t know',
    //   "status": false,
    //   "type": 'dont'
    // })
    arrVal.attributes.push({
      "label": 'Other',
      "status": false,
      "value": '',
      "type": 'textbox'
    })
    attributesVal.push(arrVal)
  }
  return attributesVal
}

export function getBase64(file) {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export function resizeImage(base64Str, maxWidth = 200, maxHeight = 175) {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}


export function removeDuplicateTags(tags) {
  let newTags = tags ?? [];
  newTags = newTags.filter(
    (value, index, self) =>
      index === self.findIndex((t) => {
        try {
          let mT = typeof (t) == 'string' ? t?.toLowerCase() : t?.param?.toLowerCase()
          let mValue = typeof (value) == 'string' ? value?.toLowerCase() : value?.param?.toLowerCase()

          let stateName = ''
          let stateCode = ''

          try {
            stateName = Iso3166.country('US')?.sub?.['US-' + (mT.toUpperCase())]?.name?.toLowerCase()
            let inxedFind = Object.values(Iso3166.country('US')?.sub)?.findIndex(item => item?.name?.toLowerCase() == mT)
            if (inxedFind >= 0) {
              stateCode = Object.keys(Iso3166.country('US')?.sub)?.[inxedFind]?.toLowerCase()
            }
          } catch (e) { }


          if (stateName || stateCode) {
            return mT === mValue || stateName === mValue || stateCode == mValue
          } else {
            return mT === mValue || (Iso3166.country(mT)?.code?.toLowerCase() === mValue || Iso3166.country(mT)?.name?.toLowerCase() === mValue)
          }

        } catch (e) {
          let mT = typeof (t) == 'string' ? t : t?.param
          let mValue = typeof (value) == 'string' ? value : value?.param
          return mT === mValue
        }
      })
  );
  return newTags;
}

export function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

export const parseDate = (date) => {
  let startDate = null
  let endDate = null
  try {
    let spDate = date?.split('·')?.[0]?.split('-')
    startDate = spDate?.length > 0 ? spDate[0]?.trim() : null
    endDate = spDate?.length > 1 ? spDate[1]?.trim() : null
  } catch (e) { console.log(e) }
  return { startDate: startDate, endDate: endDate }
}

export const getCollectionNames = (category, itemType) => {
  let collectionName;
  switch (category) {
    // case "Court Records":
    //   collectionName = itemType !== "Unicourt" && "CL_OPINIONS_ML"
    //   break
    case "Court Decisions":
      collectionName = "CL_OPINIONS_ML"
      break
    // case "News":
    //   collectionName = "METASEARCH_ML"
    //   break
    case "Negative News":
      collectionName = "NN_ML"
      break
    case "Adverse Media":
      collectionName = "NN_ML"
      break
    case "Positive News":
      collectionName = "POSN_ML"
      break
  }

  return collectionName
}

export const validateNameHasTwoWords = (input) => {
  // Trim the string to remove any leading or trailing whitespace
  const trimmedInput = input.trim();

  // Split the string by spaces
  const words = trimmedInput.split(/\s+/);
  // Check if the number of words is either 1 or 2
  if (words.length > 1) {
    return true;  // Valid case
  } else {
    return false; // Invalid case
  }
}