import React, { useContext, useEffect, useState } from "react";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";
import TickGreen from "../../images/svg/tick-green";
import QuestionMarkGrey from "../../images/svg/question-mark-grey";
import ExclamationMarkGrey from "../../images/svg/exclamation-mark-grey";
import { ButtonBase, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import ProcessingArticlesTooltip from "../onboardingTooltips/processingArticlesTooltip";
import Styles from "./personeInfo.module.css";
import LockCross from "../../images/svg/lock-cross";
import UnpaidMemberPopupModal from "../unpaidMemberModal/unpaidMemberModal";
import { UserContext } from "../../contexts/userContext";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import InfoGreen from "../../images/svg/info-green";
import ArticleCategoriesTooltip from "../onboardingTooltips/articleCategoriesTooltip";
import { articleTooltipStore } from "../../store/articleTooltipStore";

const CardItemDataCategory = (props) => {
  const { item, isClickable, onClick, verified, partial, needsReview } = props;
  const { user } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);
  const isTooltipOpen = articleTooltipStore((state) => state.isTooltipOpen);
  const setTooltipOpen = articleTooltipStore((state) => state.setTooltipOpen);

  const renderRow = (iconType, text, count) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <span
          style={{
            width: 20,
            marginRight: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          {iconType === "tick" && <TickGreen />}
          {iconType === "questionMark" && <QuestionMarkGrey />}
          {iconType === "exclamationMark" && <ExclamationMarkGrey />}
        </span>
        <span
          className={Styles.itemLabel}
          style={{
            flex: 4,
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "flex-start",
            alignSelf: "center",
          }}
        >
          {text}
        </span>
        <span
          className={Styles.itemLabel}
          style={{
            flex: 1,
            alignSelf: "flex-start",
            display: "flex",
            justifyContent: "flex-start",
            alignSelf: "center",
          }}
        >
          {" " + count}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (isTooltipOpen) {
      setTooltipOpen(false);
      setVisibleTooltip(false);
      setIsVisible(false);
    }
  }, [isTooltipOpen]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            whiteSpace: "pre-line",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            className={Styles.filterLable}
            style={!isClickable ? { color: "#8E96A8" } : {}}
          >
            {item.category === "News" ? (
              <>
                {item.category} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
              </>
            ) : (
                `${item.category}`
            )}
            {item.count > 0 && (
              <span
                className={Styles.filterLable}
                style={{
                  marginLeft: 1,
                  color: isClickable ? "#8E96A8" : "#b5b9c4",
                }}
              >
                {" (" + item.count + ")"}
              </span>
            )}

          <ArticleCategoriesTooltip
            style={{display: 'inline'}}
            category={item.category}
            isVisible={isVisibleTooltip}
            setIsVisible={setVisibleTooltip}
            onClick={() => {
              setTooltipOpen(false);
              setVisibleTooltip(false);
            }}
          >
            <ButtonBase
              style={{ paddingLeft: 4}}
              onClick={() => {
                setTooltipOpen(true);
                setTimeout(() => {
                  setVisibleTooltip(true);
                }, 100);
              }}
            >
              <InfoGreen />
            </ButtonBase>
          </ArticleCategoriesTooltip>
          </span>
          
        </div>
        {item.isProcessing && item.count > 0 && (
          <>
            <ProcessingArticlesTooltip
              setIsVisible={() => {
                setTooltipOpen(false);
                setIsVisible(false);
              }}
              isVisible={isVisible}
            >
              <div
                onClick={() => {
                  setTooltipOpen(true);
                  setTimeout(() => {
                    setIsVisible(true);
                  }, 100);
                }}
              >
                <Box
                  style={{ marginLeft: 0, marginTop: 8, marginRight: 0 }}
                  sx={{
                    width: 24,
                    "& .MuiLinearProgress-colorPrimary": {
                      backgroundColor: "#91CB6C",
                    },
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#EFF1F5",
                    },
                  }}
                >
                  <LinearProgress style={{ borderRadius: 16 }} />
                </Box>
              </div>
            </ProcessingArticlesTooltip>
          </>
        )
        }
      </div>

      {item.category === "Potential Criminal Records" && !user?.isSubscribed ? (
        <button
          onClick={() => setUnpaidMemberModal(true)}
          className={`${Styles.itemViewData} ${Styles.processingView}`}
        >
          <LockCross />
        </button>
      ) : item.isProcessing && item.count == 0 ? (
        <>
          <ProcessingArticlesTooltip setIsVisible={setIsVisible} isVisible={isVisible} >
            <button
              onClick={() => setIsVisible(true)}
              className={`${Styles.itemViewData} ${Styles.processingView}`}>
            <span>Processing...</span>

            <Box style={{ marginLeft: 16 }} sx={{
              width: 30,
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#91CB6C",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#EFF1F5",
              },
            }}>
              <LinearProgress style={{ borderRadius: 16, }} />
            </Box>
            </button>
          </ProcessingArticlesTooltip>
        </>

      ) : (
          <button
            className={Styles.itemViewData}
          onClick={() => onClick?.(item)}
          disabled={!isClickable}
        >
          <div
            style={{ flex: 3, alignItems: "center", justifyContent: "center" }}
          >
            {verified && renderRow("tick", "Verified", item.verified)}
            {partial && renderRow("questionMark", "Partial", item.partial)}
            {needsReview &&
              renderRow("exclamationMark", "Needs Review", item.needs_review)}
          </div>

          <div
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            {getFilterImage(item.category, isClickable)}
          </div>
        </button>
      )}
      {unpaidMemberModal && (
        <UnpaidMemberPopupModal
          onBackdropPress={() => {
            setUnpaidMemberModal(false);
          }}
          upgradeNow={() => {
            setSubscriptionModalVisible(true);
          }}
        />
      )}
      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
    </div>
  );
};
export default CardItemDataCategory